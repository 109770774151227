import { actionTypes } from '../../actions';

function updateVersionInArrayOfGames(list, gameId, version) {
  return list.map((item) => {
    if (item.id !== gameId) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item, Versions: [...item.Versions, version],
    };
  });
}

function updateRulebookInArrayOfGames(list, gameId, rulebook) {
  return list.map((item) => {
    if (item.id !== gameId) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    const rulebooks = item.Rulebooks || [];
    return {
      ...item, Rulebooks: [...rulebooks, rulebook],
    };
  });
}

export default function games(state = {}, action) {
  switch (action.type) {
    case actionTypes.get_games_succeeded:
      return { ...state, list: action.payload.games, areLoaded: true };
    case actionTypes.create_game_succeeded:
      return { ...state, list: [...state.list, action.payload.game], areLoaded: true };
    case actionTypes.uploading_rulebook:
      return { ...state, uploadingRulebook: action.payload.uploadingRulebook };
    case actionTypes.add_new_version_succeeded:
      return {
        ...state,
        list: updateVersionInArrayOfGames(state.list,
          action.payload.gameId, action.payload.version),
      };
    case actionTypes.upload_rulebook_success: {
      return {
        ...state,
        uploadingRulebook: false,
        list: updateRulebookInArrayOfGames(state.list,
          action.payload.gameId, action.payload.rulebookObject),
      };
    }
    default:
      return state;
  }
}
