import { toast } from 'react-toastify';
import { Storage } from 'aws-amplify';
import APIClient from '../../utils/APIClient';
import { actionTypes, actionError, actionInProgress } from '../index';

const uuidv4 = require('uuid/v4');


export function getGamesSucceeded(games) {
  return {
    type: actionTypes.get_games_succeeded,
    payload: {
      games,
    },
  };
}

export function getGames(team) {
  return (dispatch) => {
    APIClient.listObjects('games', team).then((resp) => {
      dispatch(getGamesSucceeded(resp));
    }).catch((error) => {
      dispatch(actionError(`Error loading games: ${error}`, actionTypes.get_games_error));
    });
  };
}

export function createGameSucceeded(game, history) {
  history.push(`/games/${game.id}`);
  toast.success(`Successfully created new game: ${game.Title}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.create_game_succeeded,
    payload: {
      game,
    },
  };
}

export function createGame(game, history) {
  return (dispatch) => {
    APIClient.createObject('games', game)
      .then(({ id }) => {
        dispatch(createGameSucceeded({ ...game, id }, history));
      }).catch((response) => {
        dispatch(actionError(`Error creating new game: ${response}`, actionTypes.create_game_error, '/games', history));
      });
  };
}

export function addVersionSucceeded(gameId, version) {
  toast.success(`Successfully added new version: ${version}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.add_new_version_succeeded,
    payload: {
      gameId,
      version,
    },
  };
}

export function addVersion(game, newVersion) {
  return (dispatch) => {
    const updatedGame = { id: game.id, newVersion, Team: game.Team };
    APIClient.updateObject('games', updatedGame, '/newVersion')
      .then(() => {
        dispatch(addVersionSucceeded(game.id, newVersion));
      }).catch((response) => {
        dispatch(actionError(`Error adding new version: ${response}`, actionTypes.add_new_version_error));
      });
  };
}

export function uploadRulebookSucceeded(gameId, rulebookObject) {
  toast.success(`Successfully added new rulebook: ${rulebookObject.Name}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.upload_rulebook_success,
    payload: {
      gameId,
      rulebookObject,
    },
  };
}

export function uploadRulebook(game, identityId, rulebookFile) {
  return (dispatch) => {
    dispatch(actionInProgress(actionTypes.uploading_rulebook, { uploadingRulebook: true }));
    const id = uuidv4();
    Storage.put(`${id}.pdf`, rulebookFile, {
      level: 'protected',
      contentType: 'application/pdf',
    }).then(() => {
      const newRulebookObject = {
        Name: rulebookFile.name,
        id,
        IdentityId: identityId,
        GameId: game.id,
        Team: game.Team,
      };
      APIClient.updateObject('games', newRulebookObject, '/newRulebook')
        .then(() => {
          dispatch(uploadRulebookSucceeded(game.id, newRulebookObject));
        }).catch((response) => {
          dispatch(actionInProgress(actionTypes.uploading_rulebook, { uploadingRulebook: false }));
          dispatch(actionError(`Error adding new rulebook: ${response}`, actionTypes.upload_rulebook_error));
        });
    }).catch((err) => {
      dispatch(actionInProgress(actionTypes.uploading_rulebook, { uploadingRulebook: false }));
      dispatch(actionError(`Error adding new rulebook: ${err}`, actionTypes.upload_rulebook_error));
    });
  };
}
