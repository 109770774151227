/* eslint-disable no-restricted-syntax */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import { createPlaytestingsession } from '../../actions/playtestingsessions';
import SubmitButtons from '../Forms/SubmitButtons';


class CreatePlaytestingSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      createFormValidated: false,
      disableSubmit: false,
    };
    if (props.pageGame) {
      this.state = {
        game: props.pageGame.id,
      };
    }
    this.gameTitle = '';
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleInputChange(event) {
    const { target } = event;
    const {
      name, type, checked, value,
    } = target;
    const finalValue = type === 'checkbox' ? checked : value;

    this.setState({
      [name]: finalValue,
    });
  }

  handleSubmit(event) {
    const { dispatch, history, team } = this.props;
    const {
      title, game, survey, version,
    } = this.state;
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      this.setState({
        disableSubmit: true,
      });
      const playtestingSession = {
        Title: title,
        Game: game,
        Team: team.teamObject.id,
        Survey: survey,
        Version: version,
        GameTitle: this.gameTitle,
      };
      dispatch(createPlaytestingsession(playtestingSession, history));
    }
    this.setState({ createFormValidated: true });
  }

  render() {
    let versions = [];
    const {
      open, createFormValidated, disableSubmit, game,
    } = this.state;
    const {
      games, surveys, disabled, pageGame,
    } = this.props;
    // Loading versions based on the game selected
    if (game) {
      for (const gameObject of games.list) {
        if (gameObject.id === game) {
          versions = gameObject.Versions;
          this.gameTitle = gameObject.Title;
          break;
        }
      }
    }
    return (
      <div>
        <Button disabled={disabled} size="small" variant="contained" color="primary" onClick={this.handleOpen}>
          Create Session
        </Button>
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Playtesting Session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              noValidate
              validated={createFormValidated}
              onSubmit={this.handleSubmit}
            >
              <Form.Label>Playtesting Session Title</Form.Label>
              <Form.Control required type="text" onChange={this.handleInputChange} name="title" />
              <Form.Group controlId="CreatePlaytestingSession.Game">
                <Form.Label>Game</Form.Label>
                { pageGame ? (
                  <div>
                    <b>{pageGame.Title}</b>
                  </div>
                ) : (
                  <Form.Control required as="select" onChange={this.handleInputChange} name="game" defaultValue={game}>
                    <option />
                    {games.list.map(gameObject => (
                      <option value={gameObject.id} key={gameObject.id}>{gameObject.Title}</option>
                    ))}
                  </Form.Control>
                )}
              </Form.Group>
              <Form.Group controlId="CreatePlaytestingSession.Version">
                <Form.Label>Version</Form.Label>
                <Form.Control required as="select" onChange={this.handleInputChange} name="version">
                  <option />
                  {versions.length && versions.map(version => (
                    <option value={version} key={version}>{version}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="CreatePlaytestingSession.Survey">
                <Form.Label>Survey</Form.Label>
                <Form.Control required as="select" onChange={this.handleInputChange} name="survey">
                  <option />
                  {surveys.list.map(survey => (
                    <option value={survey.id} key={survey.id}>{survey.Title}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <SubmitButtons
                handleClose={this.handleClose}
                disableSubmit={disableSubmit}
                submitLabel="Create"
                submittingLabel="Creating..."
              />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

CreatePlaytestingSession.propTypes = {
  games: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  pageGame: PropTypes.shape({
    Versions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string.isRequired,
    Team: PropTypes.string.isRequired,
  }),
  surveys: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
};

CreatePlaytestingSession.defaultProps = {
  disabled: false,
  pageGame: undefined,
};

function mapStateToProps(state) {
  return {
    team: state.team,
    games: state.games,
    surveys: state.surveys,
  };
}

export default withRouter(connect(mapStateToProps)(CreatePlaytestingSession));
