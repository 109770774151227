import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { createGame } from '../../actions/games';
import SubmitButtons from '../Forms/SubmitButtons';


class CreateGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, createFormValidated: false, disableSubmit: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.setState({
      createFormValidated: true,
    });
    const form = event.currentTarget;
    const { Title, Description } = this.state;
    const { team, history, dispatch } = this.props;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      this.setState({
        disableSubmit: true,
      });
      const game = {
        Title,
        Description,
        Team: team.teamObject.id,
        Versions: ['Initial Version'],
      };
      dispatch(createGame(game, history));
    }
  }

  render() {
    const { disabled } = this.props;
    const { open, createFormValidated, disableSubmit } = this.state;
    return (
      <div>
        <Button disabled={disabled} size="small" variant="contained" color="primary" onClick={this.handleOpen}>
          Register Game
        </Button>
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Game</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              noValidate
              validated={createFormValidated}
              onSubmit={this.handleSubmit}
            >
              <Form.Label>Game Name</Form.Label>
              <Form.Control required type="text" onChange={this.handleInputChange} name="Title" />
              <Form.Group controlId="CreateGame.Description1">
                <Form.Label>Game Description</Form.Label>
                <Form.Control required as="textarea" rows="3" name="Description" onChange={this.handleInputChange} />
              </Form.Group>
              <SubmitButtons
                handleClose={this.handleClose}
                disableSubmit={disableSubmit}
                submitLabel="Create"
                submittingLabel="Creating..."
              />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

CreateGame.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CreateGame.defaultProps = {
  disabled: false,
};

function mapStateToProps(state) {
  return {
    team: state.team,
  };
}

export default withRouter(connect(mapStateToProps)(CreateGame));
