import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import JoinPlaytestingSession from './JoinPlaytestingSession';


const QRCodeJoinPage = (props) => {
  const { match } = props;
  const code = match.params.id;
  return (
    <Container>
      <Row>
        <Col sm={8}>
          <JoinPlaytestingSession qrCode={code} />
        </Col>
      </Row>
    </Container>
  );
};

QRCodeJoinPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default QRCodeJoinPage;
