import { toast } from 'react-toastify';
import APIClient from '../../utils/APIClient';
import { actionTypes } from '../index';
import { getGames } from '../games';
import { getPlaytestingSessions } from '../playtestingsessions';
import { getSurveys } from '../surveys';

export function getTeamSucceeded(team) {
  return {
    type: actionTypes.get_team_succeeded,
    payload: {
      team,
      teamLoaded: true,
    },
  };
}

export function getNoTeam() {
  return {
    type: actionTypes.get_team_succeeded,
    payload: {
      team: null,
      teamLoaded: true,
    },
  };
}

export function getTeamError(error) {
  toast.error(`Error loading team: ${error}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.get_team_error,
    payload: {
      error,
      teamLoaded: true,
    },
  };
}

export function getUserTeam() {
  return (dispatch) => {
    APIClient.getTeams().then((resp) => {
      if (resp.teams.length === 0) {
        dispatch(getNoTeam());
      } else {
        APIClient.getObject('teams', resp.teams.values[0]).then((team) => {
          if (team) {
            dispatch(getTeamSucceeded(team));
            dispatch(getGames(team.id));
            dispatch(getPlaytestingSessions(team.id));
            dispatch(getSurveys(team.id));
          } else {
            dispatch(getNoTeam());
          }
        }).catch((errorTeam) => {
          dispatch(getTeamError(errorTeam));
        });
      }
    }).catch((error) => {
      dispatch(getTeamError(error));
    });
  };
}

export function createTeamSucceeded(team, history) {
  toast.success(`Successfully created new team: ${team.Name}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  history.push(`/team/${team.id}`);
  return {
    type: actionTypes.create_team_succeeded,
    payload: {
      team,
    },
  };
}

export function createTeamError(error) {
  toast.error(`Error creating team: ${error}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.get_team_error,
    payload: {
      error,
    },
  };
}

export function createTeam(newTeam, history) {
  return (dispatch) => {
    APIClient.createObject('teams', newTeam)
      .then(({ team }) => {
        dispatch(createTeamSucceeded(team, history));
      }).catch((response) => {
        dispatch(createTeamError(response));
      });
  };
}

export function addMemberError() {
  toast.error('Error adding member to team. Make sure that the username exists', {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.add_member_error,
  };
}

export function addMemberSucceeded(teamName, newMember) {
  toast.success(`Successfully added new member ${newMember} to team: ${teamName}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.add_member_succeeded,
    payload: {
      newMember,
    },
  };
}

export function addMember(request) {
  return (dispatch) => {
    APIClient.updateObject('teams', request, '/addMember')
      .then((response) => {
        if (response.error) {
          dispatch(addMemberError());
        } else {
          dispatch(addMemberSucceeded(request.name, request.newMember));
        }
      }).catch(() => {
        dispatch(addMemberError());
      });
  };
}
