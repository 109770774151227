import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth, Storage } from 'aws-amplify';
import { uploadRulebook } from '../../actions/games';
import CreateRulebookFeedback from '../PlaytestingSessions/CreateRulebookFeedback';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  card: {
    minHeight: theme.spacing(5),
  },
  button: {
    marginLeft: theme.spacing(3),
  },
});

class Rulebooks extends Component {
  constructor(props) {
    super(props);
    this.handleRulebookUpload = this.handleRulebookUpload.bind(this);
    this.handleRulebookDownload = this.handleRulebookDownload.bind(this);
  }

  handleRulebookUpload(e) {
    const { dispatch, game } = this.props;
    const file = e.target.files[0];
    Auth.currentUserInfo().then((result) => {
      dispatch(uploadRulebook(game, result.id, file));
    });
  }

  handleRulebookDownload(e) {
    const { game } = this.props;
    const { currentTarget } = e;
    console.log('Downloading rulebook for game ', game.Title);
    Storage.get(`${currentTarget.dataset.rulebook_id}.pdf`, {
      level: 'protected',
      identityId: currentTarget.dataset.rulebook_identity_id,
    })
      .then(result => console.log(result))
      .catch(err => console.log(err));
  }

  render() {
    const { game, games, classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Rulebooks
            </Typography>
            <Grid container direction="row">
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  component="label"
                  color="primary"
                  disabled={games.uploadingRulebook}
                  className={classes.button}
                >
                  {games.uploadingRulebook ? 'Uploading...' : 'Upload Rulebook'}
                  <input
                    type="file"
                    accept="application/pdf"
                    style={{ display: 'none' }}
                    onChange={this.handleRulebookUpload}
                  />
                </Button>
              </Grid>
              <Grid item className={classes.button}>
                <CreateRulebookFeedback pageGame={game} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Grid item className={classes.card} xs>
          {game.Rulebooks && (
            <List dense disablePadding>
              {game.Rulebooks.map(rulebook => (
                <ListItem divider key={rulebook.id}>
                  <ListItemText>
                    {rulebook.Name}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Paper>
    );
  }
}

Rulebooks.propTypes = {
  game: PropTypes.shape({
    Versions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string.isRequired,
    Team: PropTypes.string.isRequired,
  }).isRequired,
  games: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    games: state.games,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Rulebooks));
