import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FaceIcon from '@material-ui/icons/Face';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import SubmitIssue from '../NavBar/SubmitIssue';
import SignOut from './SignOut';


const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

const HeaderTitle = ({ title }) => (
  <Typography color="inherit" variant="h5" component="h1">
    {title}
  </Typography>
);

function Header(props) {
  const { classes, onDrawerToggle } = props;

  return (
    <React.Fragment>
      <AppBar
        color="primary"
        position="static"
        elevation={0}
        component="div"
        className={classes.secondaryBar}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <SubmitIssue />
            </Grid>
            <Grid item>
              <IconButton color="inherit" className={classes.iconButtonAvatar}>
                <FaceIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <SignOut classes={classes} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Route path="/" exact render={() => <HeaderTitle title="Home" />} />
              <Route path="/games" render={() => <HeaderTitle title="Games" />} />
              <Route path="/playtestingSessions" render={() => <HeaderTitle title="Playtesting Sessions" />} />
              <Route path="/surveys" render={() => <HeaderTitle title="Results" />} />
              <Route path="/rules" render={() => <HeaderTitle title="Results" />} />
              <Route path="/questionnaires" render={() => <HeaderTitle title="Results" />} />
              <Route path="/team" render={() => <HeaderTitle title="Team" />} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.shape({
    secondaryBar: PropTypes.string.isRequired,
    iconButtonAvatar: PropTypes.string.isRequired,
    menuButton: PropTypes.string.isRequired,
  }).isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Header);
