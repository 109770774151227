import { toast } from 'react-toastify';

export const actionTypes = {
  get_team: 'GET_TEAM',
  get_team_succeeded: 'GET_TEAM_SUCCEEDED',
  get_team_error: 'GET_TEAM_ERROR',
  create_team: 'CREATE_TEAM',
  create_team_succeeded: 'CREATE_TEAM_SUCCEEDED',
  create_team_error: 'CREATE_TEAM_ERROR',
  get_games_error: 'GET_GAMES_ERROR',
  get_games_succeeded: 'GET_GAMES_SUCCEEDED',
  get_surveys_error: 'GET_SURVEYS_ERROR',
  get_surveys_succeeded: 'GET_SURVEYS_SUCCEEDED',
  get_surveyresults_error: 'GET_SURVEYRESULTS_ERROR',
  get_surveyresults_succeeded: 'GET_SURVEYRESULTS_SUCCEEDED',
  get_questionnaires_error: 'GET_QUESTIONNAIRES_ERROR',
  get_questionnaires_succeeded: 'GET_QUESTIONNAIRES_SUCCEEDED',
  get_playtestingsessions_error: 'GET_PLAYTESTINGSESSIONS_ERROR',
  get_playtestingsessions_succeeded: 'GET_PLAYTESTINGSESSIONS_SUCCEEDED',
  create_game: 'CREATE_GAME',
  create_game_succeeded: 'CREATE_GAME_SUCCEEDED',
  create_game_error: 'CREATE_GAME_SUCCEEDED',
  add_new_version: 'ADD_NEW_VERSION',
  add_new_version_succeeded: 'ADD_NEW_VERSION_SUCCEEDED',
  add_new_version_error: 'ADD_NEW_VERSION_ERROR',
  filter_surveyresults: 'FILTER_SURVEYRESULTS',
  clear_surveyresults: 'CLEAR_SURVEYRESULTS',
  create_playtestingsession: 'CREATE_PLAYTESTINGSESSION',
  create_playtestingsession_succeeded: 'CREATE_PLAYTESTINGSESSION_SUCCEEDED',
  create_playtestingsession_error: 'CREATE_PLAYTESTINGSESSION_ERROR',
  join_playtestingsession: 'JOIN_PLAYTESTINGSESSION',
  join_playtestingsession_succeeded: 'JOIN_PLAYTESTINGSESSION_SUCCEEDED',
  join_playtestingsession_error: 'JOIN_PLAYTESTINGSESSION_ERROR',
  update_surveyresult: 'UPDATE_SURVEYRESULT',
  update_surveyresult_succeeded: 'UPDATE_SURVEYRESULT_SUCCEEDED',
  update_surveyresult_error: 'UPDATE_SURVEYRESULT_ERROR',
  create_survey: 'CREATE_SURVEY',
  create_survey_succeeded: 'CREATE_SURVEY_SUCCEEDED',
  create_survey_error: 'CREATE_SURVEY_ERROR',
  user_sign_out: 'USER_SIGN_OUT',
  add_member: 'ADD_MEMBER',
  add_member_error: 'ADD_MEMBER_ERROR',
  add_member_succeeded: 'ADD_MEMBER_SUCCEEDED',
  upload_rulebook: 'UPLOAD_RULEBOOK',
  upload_rulebook_success: 'UPLOAD_RULEBOOK_SUCCESS',
  upload_rulebook_error: 'UPLOAD_RULEBOOK_ERROR',
  uploading_rulebook: 'UPLOADING_RULEBOOK',
};

export function userSignOut() {
  return {
    type: actionTypes.user_sign_out,
  };
}

export function actionError(message, actionType, pathToPush = false, history = false) {
  if (pathToPush && history) {
    history.push(pathToPush);
  }
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionType,
  };
}

export function actionInProgress(actionType, payload) {
  return {
    type: actionType,
    payload,
  };
}
