import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';


const QuestionFreeForm = (props) => {
  const { onChangeInput } = props;
  return (
    <Form.Group controlId="QuestionFreeForm">
      <Form.Label>Number Of Rows</Form.Label>
      <Form.Control name="rows" min={1} max={8} type="number" required placeholder="Number of rows" onChange={onChangeInput} />
    </Form.Group>
  );
};

QuestionFreeForm.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
};

export default QuestionFreeForm;
