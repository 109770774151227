import React from 'react';
import PropTypes from 'prop-types';


class SurveyStats extends React.Component {
  static resultsByVersion(results) {
    const resultsByVersion = {};
    results.forEach((result) => {
      if (result.Version in resultsByVersion) {
        resultsByVersion[result.Version].push(result);
      } else {
        resultsByVersion[result.Version] = [result];
      }
    });
    return resultsByVersion;
  }

  render() {
    const { results } = this.props;
    const resultsByVersion = SurveyStats.resultsByVersion(results);
    return (
      <div>
        <h3>Survey Stats</h3>
        <p>
        Total Results:
          {' '}
          {results.filter(item => item.Status === 'Completed').length}
        </p>
        <p>
        Pending To Complete:
          {' '}
          {results.filter(item => item.Status !== 'Completed').length}
        </p>
        <h5>Results by Version</h5>
        {Object.keys(resultsByVersion).map(key => (
          <p key={key}>
            {key}
            {' '}
            :
            {' '}
            {resultsByVersion[key].length}
          </p>
        ))}
      </div>
    );
  }
}

SurveyStats.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    Version: PropTypes.string.isRequired,
    QuestionsList: PropTypes.arrayOf(PropTypes.shape({
      question_type: PropTypes.string.isRequired,
      response_key: PropTypes.string.isRequired,
    })),
  })).isRequired,
};

export default SurveyStats;
