import { toast } from 'react-toastify';
import APIClient from '../../utils/APIClient';
import { actionTypes } from '../index';


export function getPlaytestingSessionsSucceeded(playtestingsessions) {
  return {
    type: actionTypes.get_playtestingsessions_succeeded,
    payload: {
      playtestingsessions,
    },
  };
}

export function getPlaytestingSessionsError(error) {
  toast.error(`Error loading playtesting sessions: ${error}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.get_playtestingsessions_error,
    payload: {
      error,
    },
  };
}

export function getPlaytestingSessions(team) {
  return (dispatch) => {
    APIClient.listObjects('playtestingsessions', team).then((resp) => {
      dispatch(getPlaytestingSessionsSucceeded(resp));
    }).catch((error) => {
      dispatch(getPlaytestingSessionsError(error));
    });
  };
}

export function createPlaytestingsessionSucceeded(playtestingsession, history) {
  history.push(`/playtestingSessions/${playtestingsession.id}`);
  toast.success(`Successfully created new playtesting session: ${playtestingsession.Title}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.create_playtestingsession_succeeded,
    payload: {
      playtestingsession,
    },
  };
}

export function createPlaytestingsessionError(response, history) {
  history.push('/playtestingSessions');
  toast.error(`Error creating new playtesting session: ${response}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.create_playtestingsession_error,
  };
}

export function createPlaytestingsession(playtestingsession, history) {
  return (dispatch) => {
    APIClient.createObject('playtestingsessions', playtestingsession)
      .then((response) => {
        dispatch(createPlaytestingsessionSucceeded({
          ...playtestingsession,
          id: response.id,
          Code: response.code,
        }, history));
      }).catch((response) => {
        dispatch(createPlaytestingsessionError(response, history));
      });
  };
}

export function joinPlaytestingsessionSucceeded(surveyresult, history) {
  history.push(`/questionnaires/${surveyresult.id}`);
  toast.success('Successfully joined playtesting session', {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.join_playtestingsession_succeeded,
    payload: {
      surveyresult,
    },
  };
}

export function joinPlaytestingsessionError(response, history) {
  history.push('/');
  toast.error(`Error joining playtesting session: ${response}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.join_playtestingsession_error,
  };
}

export function joinPlaytestingsession(code, history) {
  return (dispatch) => {
    APIClient.joinSession(code)
      .then((response) => {
        if (response.error) {
          dispatch(joinPlaytestingsessionError(response.error, history));
        } else {
          dispatch(joinPlaytestingsessionSucceeded(response.surveyResult, history));
        }
      }).catch((response) => {
        dispatch(joinPlaytestingsessionError(response, history));
      });
  };
}
