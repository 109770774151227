import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import AutosizeInput from 'react-input-autosize';
import PropTypes from 'prop-types';


function autosizingRenderInput({ addTag, ...props }) {
  const { onChange, value, ...other } = props;
  return (
    <AutosizeInput type="text" onChange={onChange} value={value} {...other} />
  );
}

autosizingRenderInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  addTag: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

class QuestionChoices extends Component {
  constructor(props) {
    super(props);
    this.state = { tags: [] };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(tags) {
    const { onChangeInput } = this.props;
    this.setState({ tags });
    const event = {
      target: {
        type: 'tags',
        name: 'choices',
        value: tags,
      },
    };
    onChangeInput(event);
  }

  render() {
    const { tags } = this.state;
    return (
      <Form.Group controlId="QuestionChoices">
        <Form.Label>Choices</Form.Label>
        <TagsInput
          value={tags}
          renderInput={autosizingRenderInput}
          onChange={this.handleChange}
          required
        />
      </Form.Group>
    );
  }
}

QuestionChoices.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
};

export default QuestionChoices;
