import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getGames } from '../../actions/games';
import { getPlaytestingSessions } from '../../actions/playtestingsessions';
import { getSurveys } from '../../actions/surveys';
import { getQuestionnaires } from '../../actions/surveyresults';
import WhatIsNew from './WhatIsNew';
import GettingStarted from './GettingStarted';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';


export class Home extends React.Component {
  componentDidMount() {
    const { dispatch, team } = this.props;
    if (team.isLoaded && team.teamObject !== null) {
      dispatch(getGames(team.teamObject.id));
      dispatch(getPlaytestingSessions(team.teamObject.id));
      dispatch(getSurveys(team.teamObject.id));
    }
    dispatch(getQuestionnaires());
  }

  render() {
    const {
      team,
    } = this.props;
    if (!team.isLoaded) {
      return (<Spinner animation="border" variant="primary" />);
    }
    return (
      <GridContainer container spacing={3}>
        <GridItem xs>
          <GettingStarted />
        </GridItem>
        <GridItem xs>
          <WhatIsNew />
        </GridItem>
      </GridContainer>
    );
  }
}

Home.propTypes = {
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    games: state.games,
    surveys: state.surveys,
    playtestingsessions: state.playtestingsessions,
    surveyresults: state.surveyresults,
  };
}

export default connect(mapStateToProps)(Home);
