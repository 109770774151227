import React from 'react';
import { Form } from 'react-bootstrap';
import { PropTypes } from 'prop-types';


const QuestionGenericForm = (props) => {
  const { onChangeInput } = props;
  return (
    <Form.Group controlId="GenericQuestion">
      <Form.Label>Question Title</Form.Label>
      <Form.Control type="text" name="title" onChange={onChangeInput} required />
      <Form.Text className="text-muted">
          The title that will appear in the survey
      </Form.Text>
    </Form.Group>
  );
};

QuestionGenericForm.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
};

export default QuestionGenericForm;
