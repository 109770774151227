import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const SubmitButtons = (props) => {
  const classes = useStyles();
  const {
    handleClose, disableSubmit, submitLabel, submittingLabel,
  } = props;
  return (
    <Form.Group>
      <Button type="button" variant="contained" className={classes.button} onClick={handleClose}>Close</Button>
      <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={disableSubmit}>
        {disableSubmit ? submittingLabel : submitLabel}
      </Button>
    </Form.Group>
  );
};

SubmitButtons.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  submittingLabel: PropTypes.string.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SubmitButtons;
