import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import GridItem from '../Grid/GridItem';


const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    padding: theme.spacing(2),
  },
});

const WhatIsNew = (props) => {
  const { classes } = props;
  return (
    <Paper className={classes.paper}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            What&apos;s new?
          </Typography>
        </Toolbar>
      </AppBar>
      <GridItem>
        <List component="nav" aria-label="What is New">
          <ListItem>
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <Typography component="p">
              New feature: Request feedback in your rulebook!
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <Typography component="p">
              New UI for the website!
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <Typography component="p">
              Getting Started tutorial
            </Typography>
          </ListItem>
        </List>
      </GridItem>
    </Paper>
  );
};

WhatIsNew.propTypes = {
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(WhatIsNew);
