import React from 'react';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CreateGame from './CreateGame';
import GameCard from './GameCard';


const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  createButton: {
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginLeft: theme.spacing(2),
  },
  item: {
    paddingBottom: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
  },
});


const GamesList = (props) => {
  const { games, classes } = props;
  return (
    <Container>
      <Grid item xs={4}>
        <Paper className={classes.createButton}>
          <CreateGame />
        </Paper>
      </Grid>
      <Grid container direction="row">
        {games.list.map(game => (
          <Grid item key={game.id} xs sm={6} md={3} className={classes.card}>
            <GameCard game={game} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

GamesList.propTypes = {
  games: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    createButton: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(withRouter(GamesList));
