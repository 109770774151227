import { actionTypes } from '../../actions';

export default function team(state = {}, action) {
  switch (action.type) {
    case actionTypes.get_team_succeeded:
      return { ...state, teamObject: action.payload.team, isLoaded: true };
    case actionTypes.create_team_succeeded:
      return { ...state, teamObject: action.payload.team, isLoaded: true };
    case actionTypes.get_team_error:
      return { ...state, error: action.payload.error, isLoaded: true };
    case actionTypes.add_member_succeeded:
      return {
        ...state,
        teamObject: {
          ...state.teamObject,
          Members: [...state.teamObject.Members, action.payload.newMember],
        },
      };
    default:
      return state;
  }
}
