/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import { SignIn } from 'aws-amplify-react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Amplify from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import config from '../../aws-exports';
import createSurvey from './createSurvey.png';
import surveyResults from './surveyResults.png';


Amplify.configure(config);

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justify: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  infoElements: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  thumbnail: {
    border: '1px solid #ddd', /* Gray border */
    borderRadius: '4px', /* Rounded border */
    padding: '5px', /* Some padding */
    width: '400px', /* Set a small width */
  },
});

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }

  showComponent(theme) {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Hidden xsDown implementation="js">
          <Grid justify="center" item xs={false} sm={4} md={7} className={classes.image}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Meeple Workshop
            </Typography>
            <img className={classes.thumbnail} src={createSurvey} alt="Create Survey" />
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Create custom surveys.
            </Typography>
            <img className={classes.thumbnail} src={surveyResults} alt="Gather Survey Results" />
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              Gather actionable feedback.
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Hidden smUp implementation="js">
              <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
                Meeple Workshop
              </Typography>
              <Typography variant="h6" align="center" color="textSecondary" paragraph>
                The toolset for the game developer
              </Typography>
            </Hidden>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={this.signIn}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={this.handleInputChange}
                id="username"
                label="Username"
                key="username"
                name="username"
                type="text"
                autoComplete="username"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                key="password"
                type="password"
                id="password"
                onChange={this.handleInputChange}
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => super.changeState('forgotPassword')}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" onClick={() => super.changeState('signUp')}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomSignIn);
