import React from 'react';
import {
  XYPlot, XAxis, YAxis, HorizontalGridLines,
  VerticalGridLines, LineMarkSeries, DiscreteColorLegend,
} from 'react-vis';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';

class RatingGraph extends React.Component {
  static calculateAverage(question) {
    const data = [];
    let sum = 0;
    let avg = 0;
    Object.keys(question.results).forEach((version) => {
      sum = question.results[version].reduce((a, b) => parseInt(a, 10) + parseInt(b, 10));
      avg = sum / question.results[version].length;
      data.push({ x: version, y: avg });
    });
    return data;
  }

  render() {
    const { question } = this.props;
    let max = 10;
    if (question.metadata.value_range === '1-5') {
      max = 5;
    }
    const data = RatingGraph.calculateAverage(question);
    return (
      <div>
        <XYPlot width={300} height={300} xType="ordinal" yDomain={[0, max]}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis />
          <YAxis />
          <LineMarkSeries
            className="first-series"
            data={data}
          />
        </XYPlot>
        <DiscreteColorLegend items={['Average']} />
      </div>
    );
  }
}

RatingGraph.propTypes = {
  question: PropTypes.PropTypes.shape({
    results: PropTypes.shape({
      version: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default RatingGraph;
