import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionCard from './Questions/QuestionCard';


class QuestionsList extends Component {
  constructor(props) {
    super(props);
    this.deleteQuestion = this.deleteQuestion.bind(this);
  }

  deleteQuestion(responseKey) {
    const { deleteQuestion } = this.props;
    deleteQuestion(responseKey);
  }

  render() {
    const { metadataList } = this.props;
    if (metadataList && metadataList.length > 0) {
      return (
        <div>
          <h4> Questions </h4>
          {metadataList.map(question => (
            <QuestionCard
              deleteQuestion={this.deleteQuestion}
              key={question.response_key}
              question={question}
            />
          ))}
        </div>
      );
    }
    return (
      <div>
        <h4> Questions </h4>
      </div>
    );
  }
}

QuestionsList.propTypes = {
  deleteQuestion: PropTypes.func.isRequired,
  metadataList: PropTypes.arrayOf(PropTypes.shape({
    reponse_key: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export default QuestionsList;
