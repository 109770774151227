import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';


class QuestionRatingForm extends Component {
  constructor(props) {
    super(props);
    // Send the default value for Range of values otherwise would end up empty if it's not changed
    const event = {
      target: {
        type: 'text',
        name: 'value_range',
        value: '1-10',
      },
    };
    props.onChangeInput(event);
  }

  render() {
    const { onChangeInput } = this.props;
    return (
      <Form.Group controlId="QuestionRatingForm">
        <Form.Label>Value Range</Form.Label>
        <Form.Control as="select" name="value_range" onChange={onChangeInput} required>
          <option value="1-10">1-10</option>
          <option value="1-5">1-5</option>
        </Form.Control>
      </Form.Group>
    );
  }
}

QuestionRatingForm.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
};

export default QuestionRatingForm;
