import React from 'react';
import { Spinner } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GameVersions from './GameVersions';
import { getGames } from '../../actions/games';
import EditArchive from '../Forms/EditArchive';
import Rulebooks from './Rulebooks';

const styles = theme => ({
  spacingBottom: {
    marginTop: theme.spacing(3),
  },
});

const Game = (props) => {
  const {
    games, match, dispatch, team, classes,
  } = props;
  const game = games.list.find(g => g.id === match.params.id);
  if (game === undefined) {
    dispatch(getGames(team.teamObject.id));
    return (<Spinner animation="border" variant="primary" />);
  }
  const gameWithVersionsReversed = { ...game, Versions: [...game.Versions].reverse() };
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={7}>
        <Typography variant="h4" component="h2">
          {game.Title}
        </Typography>
        <EditArchive />
        <Grid item className={classes.spacingBottom}>
          <Typography variant="h5" component="h2">
            Description
          </Typography>
          <Typography variant="h6" component="p">
            {game.Description}
          </Typography>
        </Grid>
        <Grid item className={classes.spacingBottom}>
          <Rulebooks game={game} />
        </Grid>
      </Grid>
      <Grid item spacing={3} justify="flex-end" xs={5}>
        <GameVersions game={gameWithVersionsReversed} />
      </Grid>
    </Grid>
  );
};

Game.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  games: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        Title: PropTypes.string.isRequired,
        Description: PropTypes.string.isRequired,
        Versions: PropTypes.arrayOf(PropTypes.string),
        id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    spacingBottom: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect()(Game));
