import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const EditArchive = () => {
  const classes = useStyles();
  return (
    <div>
      <Button disabled size="small" variant="contained" className={classes.button}>
        Edit
        <EditIcon className={classes.leftIcon} />
      </Button>
      <Button disabled size="small" variant="contained" color="secondary" className={classes.button}>
        Archive
        <DeleteIcon className={classes.rightIcon} />
      </Button>
    </div>
  );
};

export default EditArchive;
