/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import ExtensionIcon from '@material-ui/icons/Extension';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';


const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

const HomeLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} to="/" exact {...props} />
));

function createLink(link) {
  return React.forwardRef((props, ref) => (
    <NavLink innerRef={ref} to={link} {...props} />
  ));
}

function Navigator(props) {
  const { classes, team, ...other } = props;
  let categories = [
    {
      id: 'Provide Feedback',
      children: [
        { id: 'Pending Feedback', icon: <DnsRoundedIcon />, path: createLink('/questionnaires') },
      ],
    },
  ];
  if (team && team.isLoaded && team.teamObject) {
    const designerMenu = {
      id: 'Design',
      children: [
        { id: 'Games', icon: <ExtensionIcon />, path: createLink('/games') },
        { id: 'Open Feedback Requests', icon: <DnsRoundedIcon />, path: createLink('/playtestingSessions') },
        { id: 'Results', icon: <ThumbsUpDownIcon />, path: createLink('/surveys') },
        { id: 'Team', icon: <PeopleIcon />, path: createLink(`/team/${team.teamObject.id}`) },
      ],
    };
    categories = [designerMenu, ...categories];
  }
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Meeple Workshop
        </ListItem>
        <ListItem
          className={clsx(classes.item, classes.itemCategory)}
          component={HomeLink}
          activeClassName={classes.itemActiveItem}
        >
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Home
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, path }) => (
              <ListItem
                component={path}
                key={childId}
                activeClassName={classes.itemActiveItem}
                button
                className={classes.item}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>

            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    itemPrimary: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
    itemIcon: PropTypes.string.isRequired,
    categoryHeader: PropTypes.string.isRequired,
    categoryHeaderPrimary: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    team: state.team,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Navigator)));
