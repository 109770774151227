/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './App.css';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { connect } from 'react-redux';
import { Hub } from 'aws-amplify';
import { getUserTeam } from './actions/teams';
import { userSignOut } from './actions';
import Content from './components/Theme/Content';
import Navigator from './components/Theme/Navigator';
import Header from './components/Theme/Header';
import 'react-toastify/dist/ReactToastify.css';
import { getQuestionnaires } from './actions/surveyresults';


const drawerWidth = 256;

const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 0',
    background: '#eaeff1',
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.state = {
      mobileOpen: false,
    };
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signOut':
          dispatch(userSignOut());
          break;
        case 'signIn':
          dispatch(getUserTeam());
          dispatch(getQuestionnaires());
          break;
        default:
          break;
      }
    });
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerToggle() {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  render() {
    const { mobileOpen } = this.state;
    const { classes, authState } = this.props;
    if (authState === 'signedIn') {
      return (
        <Router>
          <div className={classes.root}>
            <CssBaseline />
            <nav className={classes.drawer}>
              <Hidden smUp implementation="js">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={this.handleDrawerToggle}
                />
              </Hidden>
              <Hidden xsDown implementation="css">
                <Navigator PaperProps={{ style: { width: drawerWidth } }} />
              </Hidden>
            </nav>
            <div className={classes.appContent}>
              <Header onDrawerToggle={this.handleDrawerToggle} />
              <main className={classes.mainContent}>
                <Content />
              </main>
            </div>
          </div>
        </Router>
      );
    }
    return null;
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(withStyles(styles)(App));
