import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import EditArchiveCardIcons from '../Forms/EditArchiveCardIcons';
import logo from './generic-boardgame.jpg';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    minWidth: 275,
  },
  media: {
    height: 140,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const GameCard = (props) => {
  const classes = useStyles();
  const { game } = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" component="h2">
          {game.Title}
        </Typography>
        <CardMedia
          className={classes.media}
          image={logo}
          title={game.Title}
        />
      </CardContent>
      <EditArchiveCardIcons viewLink={`/games/${game.id}`} />
    </Card>
  );
};

GameCard.propTypes = {
  game: PropTypes.shape({
    Title: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
    Versions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default GameCard;
