// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:748f3727-80e7-49bf-8207-9b1c6408e902",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_tRUvj07MZ",
    "aws_user_pools_web_client_id": "3j95bn8qvel4rqi03gvs9a59mc",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "PlaytestingSessions-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "Games-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "SurveyResults-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "Surveys-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "Teams-dev",
            "region": "us-west-2"
        },
        {
            "tableName": "Users-dev",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "bgstorage-dev",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "bgapi",
            "endpoint": "https://2kda4h76ai.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "bghosting-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dorfsiue0srfn.cloudfront.net"
};


export default awsmobile;
