import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';


const ResultsList = ({ results }) => {
  function getResponse(result, question) {
    if (result.Responses[question.response_key]) {
      let response;
      if (Array.isArray(result.Responses[question.response_key])) {
        response = result.Responses[question.response_key].join(', ');
      } else {
        response = result.Responses[question.response_key];
      }
      return (
        <li key={question.response_key}>
          <p><b>{question.title}</b></p>
          <p>{response}</p>
        </li>
      );
    }
    return null;
  }

  return (
    <Accordion>
      {results.map(result => (
        <Card key={result.id}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={result.id}>
              {result.Username}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={result.id}>
            <Card.Body>
              <ul>
                {result.QuestionsList.map(question => (
                  getResponse(result, question)
                ))}
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

ResultsList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    Version: PropTypes.string.isRequired,
    QuestionsList: PropTypes.arrayOf(PropTypes.shape({
      question_type: PropTypes.string.isRequired,
      response_key: PropTypes.string.isRequired,
    })),
  })).isRequired,
};

export default ResultsList;
