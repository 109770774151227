/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import { connect } from 'react-redux';
import Game from '../Games/Game';
import GameList from '../Games/GameList';
import Home from '../Home/Home';
import TeamPage from '../Teams/TeamPage';
import SurveyEditor from '../Surveys/SurveyEditor';
import PlaytestingSession from '../PlaytestingSessions/PlaytestingSession';
import SurveyResult from '../SurveyResults/SurveyResult';
import SurveyAggregatedResults from '../SurveyResults/AggregatedResults/SurveyAggregatedResults';
import 'react-toastify/dist/ReactToastify.css';
import QRCodeJoinPage from '../PlaytestingSessions/QRCodeJoinPage';
import PlaytestingSessionsList from '../PlaytestingSessions/PlaytestingSessionsList';
import SurveyList from '../Surveys/SurveyList';
import QuestionnairesList from '../SurveyResults/QuestionnairesList';


function Content(props) {
  const {
    team, games, playtestingsessions, surveys, surveyresults,
  } = props;
  return (
    <div>
      <ToastContainer transition={Zoom} />
      {team.isLoaded && (
        <div>
          <Switch>
            <Route exact path="/" render={properties => <Home {...properties} games={games} team={team} />} />
            <Route exact path="/games" render={properties => <GameList {...properties} games={games} team={team} />} />
            <Route exact path="/games/:id" render={properties => <Game {...properties} team={team} games={games} />} />
            <Route exact path="/playtestingSessions" render={properties => <PlaytestingSessionsList {...properties} games={games} team={team} surveys={surveys} playtestingsessions={playtestingsessions} />} />
            <Route exact path="/playtestingSessions/:id" render={properties => <PlaytestingSession {...properties} games={games} playtestingsessions={playtestingsessions} surveys={surveys} />} />
            <Route exact path="/surveys" render={properties => <SurveyList {...properties} team={team} surveys={surveys} playtestingsessions={playtestingsessions} />} />
            <Route exact path="/surveys/createSurvey" render={properties => <SurveyEditor {...properties} team={team} />} />
            <Route exact path="/surveys/:id" render={properties => <SurveyAggregatedResults {...properties} surveyresults={surveyresults} team={team} />} />
            <Route exact path="/rules/summary/:playtestingsession" render={properties => <SurveyResult {...properties} playtestingsessions={playtestingsessions} surveyresults={surveyresults} team={team} />} />
            <Route exact path="/rules/:id" render={properties => <SurveyResult {...properties} surveyresults={surveyresults} playtestingsessions={playtestingsessions} team={team} />} />
            <Route exact path="/team/:id" render={properties => <TeamPage {...properties} team={team} />} />
            <Route exact path="/join/:id" render={properties => <QRCodeJoinPage {...properties} />} />
            <Route exact path="/questionnaires" render={properties => <QuestionnairesList {...properties} team={team} surveyresults={surveyresults} />} />
            <Route exact path="/questionnaires/:id" render={properties => <SurveyResult {...properties} team={team} surveyresults={surveyresults} />} />
          </Switch>
        </div>
      )}
    </div>
  );
}

Content.propTypes = {
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
  }).isRequired,
  surveys: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Team: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  games: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ),
  }).isRequired,
  playtestingsessions: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  surveyresults: PropTypes.shape({
    questionnaires: PropTypes.arrayOf(
      PropTypes.shape({
        Status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        SurveyTitle: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    team: state.team,
    games: state.games,
    playtestingsessions: state.playtestingsessions,
    surveys: state.surveys,
    surveyresults: state.surveyresults,
  };
}

export default connect(mapStateToProps)(Content);
