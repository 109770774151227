import { actionTypes } from '../../actions';

export default function playtestingsessions(state = {}, action) {
  switch (action.type) {
    case actionTypes.get_playtestingsessions_succeeded:
      return { ...state, list: action.payload.playtestingsessions, areLoaded: true };
    case actionTypes.create_playtestingsession_succeeded:
      return {
        ...state,
        list: [...state.list, action.payload.playtestingsession],
        areLoaded: true,
      };
    default:
      return state;
  }
}
