/* eslint-disable no-restricted-syntax */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Modal, Form } from 'react-bootstrap';
import { createPlaytestingsession } from '../../actions/playtestingsessions';
import SubmitButtons from '../Forms/SubmitButtons';


class CreateRulebookFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, createFormValidated: false, disableSubmit: false };
    if (props.pageGame) {
      this.state = {
        game: props.pageGame.id,
      };
    }
    this.gameTitle = '';
    this.rulebooks = [];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleInputChange(event) {
    const { target } = event;
    const {
      name, type, checked, value,
    } = target;
    const finalValue = type === 'checkbox' ? checked : value;
    if (name === 'rulebook') {
      const rulebook = this.rulebooks.find(r => r.id === finalValue);
      this.setState({
        rulebookName: rulebook.Name,
        rulebookIdentityId: rulebook.IdentityId,
      });
    }
    this.setState({
      [name]: finalValue,
    });
  }

  handleSubmit(event) {
    const { dispatch, history, team } = this.props;
    const {
      title, game, rulebook, rulebookName, rulebookIdentityId,
    } = this.state;
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      this.setState({
        disableSubmit: true,
      });
      const playtestingSession = {
        Title: title,
        Game: game,
        Team: team.teamObject.id,
        Rulebook: rulebook,
        RulebookName: rulebookName,
        RulebookIdentityId: rulebookIdentityId,
        GameTitle: this.gameTitle,
      };
      dispatch(createPlaytestingsession(playtestingSession, history));
    }
    this.setState({ createFormValidated: true });
  }

  render() {
    let rulebooks = [];
    const {
      open, createFormValidated, disableSubmit, game,
    } = this.state;
    const { games, disabled, pageGame } = this.props;
    // Loading rulebooks based on the game selected
    if (game) {
      for (const gameObject of games.list) {
        if (gameObject.id === game) {
          if (gameObject.Rulebooks) {
            rulebooks = gameObject.Rulebooks;
            this.rulebooks = gameObject.Rulebooks;
          }
          this.gameTitle = gameObject.Title;
          break;
        }
      }
    }
    return (
      <div>
        <Button disabled={disabled} size="small" variant="contained" color="primary" onClick={this.handleOpen}>
          Create Rulebook Feedback
        </Button>
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create Rulebook Feedback Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              noValidate
              validated={createFormValidated}
              onSubmit={this.handleSubmit}
            >
              <Form.Label>Rulebook Feedback Title</Form.Label>
              <Form.Control required type="text" onChange={this.handleInputChange} name="title" />
              <Form.Group controlId="CreatePlaytestingSession.Game">
                <Form.Label>Game</Form.Label>
                { pageGame ? (
                  <div>
                    <b>{pageGame.Title}</b>
                  </div>
                ) : (
                  <Form.Control required as="select" onChange={this.handleInputChange} name="game" defaultValue={game}>
                    <option />
                    {games.list.map(gameObject => (
                      <option value={gameObject.id} key={gameObject.id}>{gameObject.Title}</option>
                    ))}
                  </Form.Control>
                )}
              </Form.Group>
              <Form.Group controlId="CreatePlaytestingSession.Version">
                <Form.Label>Rulebook</Form.Label>
                <Form.Control required as="select" onChange={this.handleInputChange} name="rulebook">
                  <option />
                  {rulebooks.length && rulebooks.map(rulebook => (
                    <option value={rulebook.id} key={rulebook.id}>{rulebook.Name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <SubmitButtons
                handleClose={this.handleClose}
                disableSubmit={disableSubmit}
                submitLabel="Create"
                submittingLabel="Creating..."
              />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

CreateRulebookFeedback.propTypes = {
  games: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  surveys: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  pageGame: PropTypes.shape({
    Versions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string.isRequired,
    Team: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
};

CreateRulebookFeedback.defaultProps = {
  disabled: false,
  pageGame: undefined,
};

function mapStateToProps(state) {
  return {
    team: state.team,
    games: state.games,
    surveys: state.surveys,
  };
}

export default withRouter(connect(mapStateToProps)(CreateRulebookFeedback));
