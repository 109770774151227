import { actionTypes } from '../../actions';

export default function surveys(state = {}, action) {
  switch (action.type) {
    case actionTypes.get_surveys_succeeded:
      return { ...state, list: action.payload.surveys, areLoaded: true };
    case actionTypes.create_survey_succeeded:
      return { ...state, list: [...state.list, action.payload.survey], areLoaded: true };
    default:
      return state;
  }
}
