import React, { Component } from "react";
import {
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight
} from "react-pdf-highlighter";
import Spinner from "./Spinner";
import Sidebar from "./Sidebar";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import pdfjs from 'pdfjs-dist/webpack';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { updateSurveyresult } from '../../actions/surveyresults';
import "./style/App.css";
import APIClient from "../../utils/APIClient";

const styles = theme => ({
  gridItem: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2),
  }
});

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () => window.location.hash.slice("#highlight-".length);

const resetHash = () => {
  window.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.text}
    </div>
  ) : null;

class RulesViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledPublishComments: false,
      hideOtherComments: false,
    };
    if (props.feedbackSession) {
      const ownHighlights = props.feedbackSession.Responses ? [...props.feedbackSession.Responses] : [];
      this.state = {
        ownHighlights: ownHighlights,
        namedHighlights: [ ownHighlights.map(h => {
           return {user: props.feedbackSession.Username, highlight: h }}) ].flat(),
        playtestingSessionId: props.feedbackSession.PlaytestingSession,
        username: props.feedbackSession.Username,
        rulebook: props.feedbackSession.Rulebook,
        rulebookIdentityId: props.feedbackSession.RulebookIdentityId,
      }
    } else {
      this.state = {
        playtestingSessionId: props.playtestingSession.id,
        namedHighlights: [],
        ownHighlights: [],
        username: props.playtestingSession.Username,
        rulebook: props.playtestingSession.Rulebook,
        rulebookIdentityId: props.playtestingSession.RulebookIdentityId,
      }
    }
    this.getHighlightById = this.getHighlightById.bind(this);
    this.resetHighlights = this.resetHighlights.bind(this);
    this.scrollToHighlightFromHash = this.scrollToHighlightFromHash.bind(this);
    this.publishComments = this.publishComments.bind(this);
    this.hideOtherComments = this.hideOtherComments.bind(this);
    this.removeHighlight = this.removeHighlight.bind(this);

  }

  resetHighlights() {
    const { namedHighlights, username } = this.state;
    this.setState({
      namedHighlights: namedHighlights.filter(h => h.user !== username )
    });
  };

  scrollViewerTo(highlight) {};

  scrollToHighlightFromHash() {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  async componentDidMount() {
    const { playtestingSessionId, username, rulebook, rulebookIdentityId } = this.state;
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false,
    );
    APIClient.listObjects('surveyresults', playtestingSessionId, 'playtestingSession').then((resp) => {
      const otherResponses = resp.filter(r => r.Username !== username && r.Responses);
      const mapResponses = otherResponses.map(r => {
         return {user: r.Username, responses: r.Responses }
        });
      const flattedResponses = mapResponses.reduce((acc, record) => {
        record.responses.map(resp => {
          acc.push({user: record.user, highlight: resp});
        })
        return acc;
      }, []);;
      this.setState(prevState => ({
        namedHighlights: [...prevState.namedHighlights, ...flattedResponses],
      }));
    }).catch((error) => {
      console.log('Error loading results', error)
    });
    Storage.get(`${rulebook}.pdf`, {
      level: 'protected', 
      identityId: rulebookIdentityId 
    })
    .then(result => {
      pdfjs.getDocument(result)
      .then(pdfDocument => {
        this.setState({
          pdfDocument: pdfDocument
        });
      }).catch((error) => {
        console.log("error is ", error);
      });
    })
    .catch(err => console.log(err));

    
  }

  getHighlightById(id) {
    const { namedHighlights } = this.state;
    return namedHighlights.map(h => h.highlight).find(highlight => highlight.id === id);
  }

  addHighlight(highlight) {
    const { ownHighlights, namedHighlights, username } = this.state;
    // We don't want emojis in the comments! Will need to create own Tip component.
    highlight.emoji = undefined;
    highlight.comment.emoji = undefined;
    const newHighlight = { ...highlight, id: getNextId() };
    this.setState({
      ownHighlights: [...ownHighlights, newHighlight],
      namedHighlights: [...namedHighlights, { user: username, highlight: newHighlight}]
    });
  }

  removeHighlight(event) {
    const highlightId = event.currentTarget.dataset.highlight_id;
    this.setState(prevState => ({
      ownHighlights: prevState.ownHighlights.filter(h => h.id !== highlightId),
      namedHighlights: prevState.namedHighlights.filter(h => h.highlight.id !== highlightId),
    }));
  }

  publishComments() {
    this.setState({
      disabledPublishComments: true,
    })
    const { feedbackSession, history, dispatch } = this.props;
    const { ownHighlights } = this.state;
    const updatedQuestionnaire = { id: feedbackSession.id, responses: ownHighlights, statusSurvey: 'Completed' };
    dispatch(updateSurveyresult(updatedQuestionnaire, history));
  }

  hideOtherComments() {
    this.setState(prevState => ({
      hideOtherComments: !prevState.hideOtherComments,
    }));
  }

  updateHighlight(highlightId, position, content) {
    this.setState({
      highlights: this.state.namedHighlights.map(h => {
        if (h.highlight.id === highlightId) {
          return {
            ...h,
            highlight: {
              position: { ...h.position, ...position },
              content: { ...h.content, ...content }
            }
          }
        } else {
          return h;
        }
      })
    });
  }

  render() {
    const { pdfDocument, disabledPublishComments, namedHighlights, hideOtherComments, username } = this.state;
    const { classes, feedbackSession } = this.props;
    let highlightsArray;
    let namedHighlightsArray;
    if (hideOtherComments) {
      highlightsArray = namedHighlights.filter(h => h.user === username).map(h => h.highlight);
      namedHighlightsArray = namedHighlights.filter(h => h.user === username);
    } else {
      highlightsArray = namedHighlights.map(h => h.highlight);
      namedHighlightsArray = namedHighlights;
    }
    if (pdfDocument === undefined) {
      return (<Spinner animation="border" variant="primary" />);
    }
    return (
      <Grid container direction="column">
        { feedbackSession &&
          <Grid item xs className={classes.gridItem}>
            <Button disabled={disabledPublishComments} size="small" variant="contained" color="primary" onClick={this.publishComments}>
              {disabledPublishComments ? 'Publishing...' : 'Publish Comments'}
            </Button>
            <Button size="small" variant="contained" color="primary" className={classes.button} onClick={this.hideOtherComments}>
              { hideOtherComments ? 'Show Other Comments' : 'Hide Other Comments'}
            </Button>
            <Button size="small" variant="contained" color="secondary" className={classes.button} onClick={this.resetHighlights}>
              Reset Comments
            </Button>
          </Grid>
        }
        <Grid item xs className={classes.gridItem}>
          <div className="App" style={{ display: "flex", height: "100vh" }}>
            <Sidebar
              highlights={namedHighlightsArray}
              removeHighlight={this.removeHighlight}
              user={username}
            />
            <div
              style={{
                height: "100vh",
                width: "75vw",
                overflowY: "scroll",
                position: "relative"
              }}
            >
              {pdfDocument &&
                <PdfHighlighter
                  pdfDocument={pdfDocument}
                  onScrollChange={resetHash}
                  scrollRef={scrollTo => {
                    this.scrollViewerTo = scrollTo;
                    this.scrollToHighlightFromHash();
                  }}
                  onSelectionFinished={(
                    position,
                    content,
                    hideTipAndSelection,
                    transformSelection
                  ) => (
                    <Tip
                      onOpen={transformSelection}
                      onConfirm={comment => {
                        this.addHighlight({ content, position, comment });
                        hideTipAndSelection();
                      }}
                    />
                  )}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    isScrolledTo
                  ) => {

                    const component = (
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    )
                    return (
                      <Popup
                        popupContent={<HighlightPopup {...highlight} />}
                        onMouseOver={popupContent =>
                          setTip(highlight, highlight => popupContent)
                        }
                        onMouseOut={hideTip}
                        key={index}
                        children={component}
                      />
                    );
                  }}
                  highlights={highlightsArray}
                />
              }
            </div>
          </div>
        </Grid>
      </Grid>
     );
  }
}

function mapStateToProps(state) {
  return {
    surveyresults: state.surveyresults,
  };
}

export default withStyles(styles)(withRouter(connect(mapStateToProps)(RulesViewer)));