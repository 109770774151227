import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import APIClient from '../../utils/APIClient';
import SubmitButtons from '../Forms/SubmitButtons';


class SubmitIssue extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      disableSubmit: false,
      open: false,
      Type: 'bug',
      createFormValidated: false,
    };
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.setState({
      disableSubmit: true,
      createFormValidated: true,
    });
    const { Title, Description, Type } = this.state;
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      const contact = { Title, Description, Type };
      APIClient.createObject('contact', contact).then(() => {
        toast.success('Successfully submitted feedback. Thanks!', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({
          open: false,
          disableSubmit: false,
        });
      }).catch(({ error }) => {
        toast.success(`Unable to submit feedback: ${error}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({
          open: false,
          disableSubmit: false,
        });
      });
    }
  }

  handleOpen() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const {
      open, Type, createFormValidated, disableSubmit,
    } = this.state;
    return (
      <Tooltip title="Help">
        <div>
          <IconButton color="inherit" onClick={this.handleOpen}>
            <HelpIcon />
          </IconButton>
          <Modal show={open} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Submit Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate validated={createFormValidated} onSubmit={this.handleSubmit}>
                <Form.Group controlId="feedbacktitle">
                  <Form.Label>Feedback Title</Form.Label>
                  <Form.Control required type="text" onChange={this.handleInputChange} name="Title" />
                </Form.Group>
                <Form.Group controlId="feedbacktitle">
                  <Form.Label>Feedback Type</Form.Label>
                  <Form.Control required as="select" onChange={this.handleInputChange} value={Type} name="Type">
                    <option value="bug" key="bug">Bug</option>
                    <option value="feature request" key="feature request">Feature Request</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="feedbackDescription">
                  <Form.Label>Feedback Description</Form.Label>
                  <Form.Control required as="textarea" rows="6" onChange={this.handleInputChange} name="Description" />
                </Form.Group>
                <SubmitButtons
                  handleClose={this.handleClose}
                  disableSubmit={disableSubmit}
                  submitLabel="Submit"
                  submittingLabel="Submitting..."
                />
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </Tooltip>
    );
  }
}

export default SubmitIssue;
