import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import JoinPlaytestingSession from '../PlaytestingSessions/JoinPlaytestingSession';

const styles = theme => ({
  createButton: {
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  list: {
    backgroundColor: '#fff',
  },
  gridList: {
    margin: theme.spacing(2),
  },
});

const QuestionnairesList = (props) => {
  const { surveyresults, classes } = props;
  return (
    <Grid container direction="column">
      <Grid item>
        <JoinPlaytestingSession />
      </Grid>
      <Grid container direction="row">
        <Grid item xs={5} className={classes.gridList}>
          <Typography variant="h6" component="h2">
            Playtesting Sessions
          </Typography>
          <List className={classes.list}>
            {surveyresults.questionnaires.filter(questionnaire => questionnaire.Status !== 'Completed' && questionnaire.Version).map(questionnaire => (
              <Link to={`/questionnaires/${questionnaire.id}`} key={questionnaire.id}>
                <ListItem divider button>
                  {questionnaire.SurveyTitle}
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
        <Grid item xs={5} className={classes.gridList}>
          <Typography variant="h6" component="h2">
            Rulebook Feedback Requests
          </Typography>
          <List className={classes.list}>
            {surveyresults.questionnaires.filter(questionnaire => questionnaire.Status !== 'Completed' && questionnaire.Rulebook).map(questionnaire => (
              <Link to={`/questionnaires/${questionnaire.id}`} key={questionnaire.id}>
                <ListItem divider button>
                  {questionnaire.RulebookName}
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

QuestionnairesList.propTypes = {
  surveyresults: PropTypes.shape({
    questionnaires: PropTypes.arrayOf(
      PropTypes.shape({
        Status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        SurveyTitle: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    createButton: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(QuestionnairesList);
