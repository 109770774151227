import React from 'react';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CreatePlaytestingSession from './CreatePlaytestingSession';
import PlaytestingSessionsCard from './PlaytestingSessionsCard';
import CreateRulebookFeedback from './CreateRulebookFeedback';

const styles = theme => ({
  createButton: {
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginLeft: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
  },
});

const PlaytestingSessionsList = (props) => {
  const {
    classes, playtestingsessions,
  } = props;
  return (
    <Container spacing={4}>
      <Grid container direction="row">
        <Grid item>
          <Paper className={classes.createButton}>
            <CreatePlaytestingSession />
          </Paper>
        </Grid>
        <Grid item>
          <Paper className={classes.createButton}>
            <CreateRulebookFeedback />
          </Paper>
        </Grid>
      </Grid>
      <Grid container direction="row">
        {playtestingsessions.list.map(playtestingsession => (
          <Grid key={playtestingsession.id} xs={12} sm={6} md={3} className={classes.card}>
            <PlaytestingSessionsCard playtestingsession={playtestingsession} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

PlaytestingSessionsList.propTypes = {
  playtestingsessions: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    createButton: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(withRouter(PlaytestingSessionsList));
