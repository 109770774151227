import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Questionnaire from './Questionnaire';
import RulesViewer from '../RulesFeedback/RulesViewer';

const SurveyResult = (props) => {
  const { surveyresults, match, playtestingsessions } = props;
  let playtestingsession;
  let gameTitle;
  let surveyresult;
  if (match.params.playtestingsession) {
    playtestingsession = playtestingsessions.list
      .find(s => s.id === match.params.playtestingsession);
  }
  if (match.params.id) {
    surveyresult = surveyresults.questionnaires.find(s => s.id === match.params.id);
  }
  if (surveyresult === undefined && playtestingsession === undefined) {
    return (<Spinner animation="border" variant="primary" />);
  }
  if (playtestingsession) {
    gameTitle = playtestingsession.GameTitle;
  } else if (surveyresult) {
    gameTitle = surveyresult.GameTitle;
  }
  return (
    <Grid container direction="column">
      <Grid item xs={4}>
        <Typography variant="h5" component="h2">
          Game Information
        </Typography>
        <p>
          <b>Name:</b>
          {' '}
          {gameTitle}
        </p>
        {(surveyresult && surveyresult.RulebookName) && (
          <p>
            <b>Rulebook:</b>
            {' '}
            {surveyresult.RulebookName}
          </p>
        )}
        {(surveyresult && surveyresult.Version) && (
          <p>
            <b>Version:</b>
            {' '}
            {surveyresult.Version}
          </p>
        )}
      </Grid>
      <Grid item xs={9}>
        {((surveyresult && surveyresult.RulebookName)
        || (playtestingsession && playtestingsession.RulebookName)) && (
          <RulesViewer
            feedbackSession={surveyresult}
            playtestingSession={playtestingsession}
          />
        )}
        {(surveyresult && surveyresult.SurveyTitle && surveyresult.QuestionsList) && (
          <div>
            <h3>{surveyresult.SurveyTitle}</h3>
            <Questionnaire survey={surveyresult} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

SurveyResult.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  surveyresults: PropTypes.shape({
    questionnaires: PropTypes.arrayOf(
      PropTypes.shape({
        Status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        SurveyTitle: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  playtestingsessions: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }),
};

SurveyResult.defaultProps = {
  playtestingsessions: undefined,
};

export default SurveyResult;
