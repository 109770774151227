/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';


const updateHash = (highlight) => {
  window.location.hash = `highlight-${highlight.id}`;
};

function Sidebar(props) {
  const { highlights, removeHighlight, user } = props;
  return (
    <div className="sidebar" style={{ width: '25vw' }}>
      <div className="description" style={{ padding: '1rem' }}>
        <p>
          <small>
            To create a comment select the text and click Add highlight.
          </small>
        </p>
      </div>
      <ul className="sidebar__highlights">
        {highlights.map(highlight => (
          <li
            key={highlight.highlight.id}
            className="sidebar__highlight"
            onClick={() => {
              updateHash(highlight.highlight);
            }}
          >
            <div>
              <strong>Comment by:</strong>
              <p>{highlight.user}</p>
              <strong>{highlight.highlight.comment.text}</strong>
              {highlight.highlight.content.text ? (
                <blockquote style={{ marginTop: '0.5rem' }}>
                  {`${highlight.highlight.content.text.slice(0, 90).trim()}…`}
                </blockquote>
              ) : null}
              {highlight.highlight.content.image ? (
                <div
                  className="highlight__image"
                  style={{ marginTop: '0.5rem' }}
                >
                  <img src={highlight.highlight.content.image} alt="Screenshot" />
                </div>
              ) : null}
            </div>
            <div className="highlight__location">
              Page {highlight.highlight.position.pageNumber}
              { user === highlight.user && (
                <IconButton size="small" onClick={removeHighlight} data-highlight_id={highlight.highlight.id}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </li>
        ))};
      </ul>
    </div>
  );
}

export default Sidebar;
