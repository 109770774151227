import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createTeam } from '../../actions/teams';
import SubmitButtons from '../Forms/SubmitButtons';


class CreateTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, disableSubmit: false, createFormValidated: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    this.setState({ createFormValidated: true });
    const { name } = this.state;
    const { history, dispatch } = this.props;
    const team = { Name: name };
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      this.setState({ disableSubmit: true });
      dispatch(createTeam(team, history));
    }
  }

  handleOpen() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { disabled } = this.props;
    const { open, createFormValidated, disableSubmit } = this.state;
    return (
      <div>
        <Button disabled={disabled} size="small" variant="contained" color="primary" onClick={this.handleOpen}>
          Create New Team
        </Button>
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              noValidate
              validated={createFormValidated}
              onSubmit={this.handleSubmit}
            >
              <Form.Group>
                <Form.Label>Team Name</Form.Label>
                <Form.Control required type="text" onChange={this.handleInputChange} name="name" />
              </Form.Group>
              <SubmitButtons
                handleClose={this.handleClose}
                disableSubmit={disableSubmit}
                submitLabel="Create"
                submittingLabel="Creating..."
              />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

CreateTeam.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CreateTeam.defaultProps = {
  disabled: false,
};

export default withRouter(connect()(CreateTeam));
