import { API } from 'aws-amplify';


const apiName = 'bgapi';

class APIClient {
  static getObject(modelName, id) {
    const path = `/${modelName}/object/${id}`;
    const params = {
      headers: {},
    };
    return API.get(apiName, path, params);
  }

  static getTeams() {
    const path = '/users/object/teams';
    const params = {
      headers: {},
    };
    return API.get(apiName, path, params);
  }

  static listObjects(modelName, parameter = false, extraPath = false) {
    let path = `/${modelName}`;
    if (extraPath) {
      path = `${path}/${extraPath}`;
    }
    if (parameter) {
      path = `${path}/${parameter}`;
    }
    const params = {
      headers: {},
    };
    return API.get(apiName, path, params);
  }

  static listResultsBySurvey(team, survey) {
    const path = `/surveyresults/${team}/survey/${survey}`;
    const params = {
      headers: {},
    };

    return API.get(apiName, path, params);
  }

  static createObject(modelName, object) {
    const path = `/${modelName}`;
    const params = {
      body: object,
      headers: {},
    };
    return API.post(apiName, path, params);
  }

  static updateObject(modelName, object, extraPath = '') {
    const path = `/${modelName}${extraPath}`;
    const params = {
      body: object,
      headers: {},
    };
    return API.put(apiName, path, params);
  }

  static async joinSession(sessionId) {
    const path = `/playtestingsessions/joinSession/${sessionId}`;
    const params = {
      headers: {},
    };
    return API.get(apiName, path, params);
  }
}

export default APIClient;
