import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Container, Col, ListGroup, Spinner,
} from 'react-bootstrap';
import AddMember from './AddMember';

const TeamPage = (props) => {
  const { team } = props;
  if (team.teamObject == null) {
    return (<Spinner animation="border" variant="primary" />);
  }
  return (
    <Container>
      <Row>
        <Col sm={8}>
          <h4>
            Team Name
          </h4>
          <p>
            {team.teamObject.Name}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <h4>
            Team Members
          </h4>
          <ListGroup>
            {team.teamObject.Members.map(member => (
              <ListGroup.Item key={member}>{member}</ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={4}>
          <AddMember team={team.teamObject} />
        </Col>
      </Row>
    </Container>
  );
};

TeamPage.propTypes = {
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
};

export default TeamPage;
