import React, { Component } from 'react';
import {
  InputGroup, FormControl,
} from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addVersion } from '../../actions/games';
import { actionTypes, actionError } from '../../actions';
import CreatePlaytestingSession from '../PlaytestingSessions/CreatePlaytestingSession';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  button: {
    marginLeft: theme.spacing(3),
  },
});

class GameVersions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newVersion: '',
    };
    this.createNewVersion = this.createNewVersion.bind(this);
    this.handleSurveyVersionChange = this.handleSurveyVersionChange.bind(this);
  }

  handleSurveyVersionChange(event) {
    const { value } = event.target;
    this.setState({ newVersion: value });
  }

  createNewVersion() {
    const { newVersion } = this.state;
    const { game, dispatch } = this.props;
    if (newVersion !== '') {
      dispatch(addVersion(game, newVersion));
      this.setState({
        newVersion: '',
      });
    } else {
      dispatch(actionError('A new version cannot be empty', actionTypes.add_new_version_error));
    }
  }

  render() {
    const { newVersion } = this.state;
    const { game, classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Versions
            </Typography>
            <Grid container direction="row">
              <Grid item className={classes.button}>
                <CreatePlaytestingSession pageGame={game} />
              </Grid>
              <Grid item className={classes.button}>
                <Link to="/surveys/createSurvey">
                  <Button size="small" variant="contained" color="primary">
                    Create Survey
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Add New Version"
            as="textarea"
            rows="2"
            aria-label="Add New Version"
            aria-describedby="basic-addon2"
            onChange={this.handleSurveyVersionChange}
            value={newVersion}
          />
          <InputGroup.Append>
            <Button onClick={this.createNewVersion} size="small" variant="contained" color="primary">Create New Version</Button>
          </InputGroup.Append>
        </InputGroup>
        <List>
          {game.Versions.map(version => (
            <ListItem divider key={version}>{version}</ListItem>
          ))}
        </List>
      </Paper>
    );
  }
}

GameVersions.propTypes = {
  game: PropTypes.shape({
    Versions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string.isRequired,
    Team: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect()(GameVersions));
