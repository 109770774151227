import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CreateTeam from '../Teams/CreateTeam';
import CreateGame from '../Games/CreateGame';
import CreatePlaytestingSession from '../PlaytestingSessions/CreatePlaytestingSession';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ['Create a team', 'Register a game', 'Start a playtesting session', 'Create a survey'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Create your company/team so you can start registering games and creating surveys. You will be
              able to add members to your team after it has been created, through the Team section.`;
    case 1:
      return `Register your newest prototype that you want to gather feedback from. You can define the
              title of the game, a small description and you can also define the current version
              number of your prototype.`;
    case 2:
      return `Create a survey to capture structured feedback after
              a playtesting session. You can define different types of questions based on the type of feedback
              that you're trying to capture`;
    case 3:
      return `Create a playtesting session by selecting the game and version that is being tested, and a survey.
              You can share either the link to the playtesting session, 
              the code (if someone already has Meeple Workshop on their phone), or you can just
              let your testers scan your QR code!`;
    default:
      return 'Unknown step';
  }
}

function getStepLabel(step, activeStep) {
  let disabled = true;
  if (step === activeStep) {
    disabled = false;
  }
  switch (step) {
    case 0:
      return <CreateTeam disabled={disabled} />;
    case 1:
      return <CreateGame disabled={disabled} />;
    case 2:
      return (
        <Link to="/surveys/createSurvey">
          <Button disabled={disabled} size="small" variant="contained" color="primary">
            Create Survey
          </Button>
        </Link>
      );
    case 3:
      return <CreatePlaytestingSession disabled={disabled} />;
    default:
      return 'Unknown step';
  }
}

function determineActiveStep(team, games, surveys, playtestingsessions) {
  if ((!team || !team.isLoaded)) {
    return null;
  }
  if (team.teamObject === null) {
    return 0;
  }
  if (!games.areLoaded || !surveys.areLoaded || !playtestingsessions.areLoaded) {
    return null;
  }
  if (games.list.length === 0) {
    return 1;
  }
  if (surveys.list.length === 0) {
    return 2;
  }
  if (playtestingsessions.list.length === 0) {
    return 3;
  }
  return 4;
}

const GettingStarted = (props) => {
  const classes = useStyles();
  const steps = getSteps();
  const {
    team, games, surveys, playtestingsessions,
  } = props;
  const activeStep = determineActiveStep(team, games, surveys, playtestingsessions);
  return (
    <Paper className={classes.paper}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Getting Started
          </Typography>
        </Toolbar>
      </AppBar>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{getStepLabel(index, activeStep)}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
        </Paper>
      )}
    </Paper>
  );
};

GettingStarted.propTypes = {
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  surveys: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Team: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  games: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ),
  }).isRequired,
  playtestingsessions: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    team: state.team,
    games: state.games,
    surveys: state.surveys,
    playtestingsessions: state.playtestingsessions,
  };
}

export default connect(mapStateToProps)(GettingStarted);
