import React from 'react';
import PropTypes from 'prop-types';
import RatingGraph from './RatingGraph';
import SingleChoiceGraph from './SingleChoiceGraph';


class SurveyGraphList extends React.Component {
  constructor(props) {
    super(props);
    this.resultsByResponseKeyAndVersion = this.resultsByResponseKeyAndVersion.bind(this);
  }

  resultsByResponseKeyAndVersion() {
    // We iterate through the results and create a hash of response_keys
    // that contains metadata of the question
    // and the results, which is a hash of arrays with the actual results
    const resultsByResponseKeyAndVersion = {};
    const { results } = this.props;
    results.forEach((result) => {
      result.QuestionsList.forEach((question) => {
        if (question.question_type !== 'freeform') {
          if (!(Object.prototype.hasOwnProperty.call(
            resultsByResponseKeyAndVersion, question.response_key,
          ))) {
            resultsByResponseKeyAndVersion[question.response_key] = {};
            resultsByResponseKeyAndVersion[question.response_key].metadata = question;
            resultsByResponseKeyAndVersion[question.response_key].results = {};
          }
          if (!(Object.prototype.hasOwnProperty.call(
            resultsByResponseKeyAndVersion[question.response_key].results, result.Version,
          ))) {
            resultsByResponseKeyAndVersion[question.response_key].results[result.Version] = [];
          }
          resultsByResponseKeyAndVersion[question.response_key].results[result.Version]
            .push(result.Responses[question.response_key]);
        }
      });
    });
    return resultsByResponseKeyAndVersion;
  }

  static renderQuestionAggregation(question) {
    switch (question.metadata.question_type) {
      case 'rating':
        return (<RatingGraph question={question} />);
      case 'singlechoice':
      case 'multichoice':
        return (<SingleChoiceGraph question={question} />);
      default:
        return null;
    }
  }

  render() {
    const resultsByResponseKeyAndVersion = this.resultsByResponseKeyAndVersion();
    const keys = Object.keys(resultsByResponseKeyAndVersion);
    return (
      <div>
        <h3>Responses</h3>
        {keys.map(key => (
          <div key={key}>
            <h5>{resultsByResponseKeyAndVersion[key].metadata.title}</h5>
            {SurveyGraphList.renderQuestionAggregation(resultsByResponseKeyAndVersion[key])}
          </div>
        ))}
      </div>
    );
  }
}

SurveyGraphList.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    Version: PropTypes.string.isRequired,
    QuestionsList: PropTypes.arrayOf(PropTypes.shape({
      question_type: PropTypes.string.isRequired,
      response_key: PropTypes.string.isRequired,
    })),
  })).isRequired,
};

export default SurveyGraphList;
