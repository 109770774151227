import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { joinPlaytestingsession } from '../../actions/playtestingsessions';
import SubmitButtons from '../Forms/SubmitButtons';


class JoinPlaytestingSession extends React.Component {
  constructor(props) {
    super(props);
    // If we're sending the QR Code, start with the modal open
    if (props.qrCode) {
      this.state = { open: true, code: props.qrCode, disableSubmit: false };
    } else {
      this.state = { open: false, disableSubmit: false };
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      disableSubmit: true,
    });
    const { code } = this.state;
    const { history, dispatch } = this.props;
    dispatch(joinPlaytestingsession(code, history));
    this.setState({
      open: false,
    });
  }

  handleOpen() {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { qrCode } = this.props;
    const { open, disableSubmit } = this.state;
    return (
      <div>
        {qrCode === null && (
          <Button size="small" variant="contained" color="primary" onClick={this.handleOpen}>
            Join Session
          </Button>
        )}
        <Modal show={open} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Join Playtesting Session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="JoinPlaytestingSession.Code">
                <Form.Label>Session code</Form.Label>
                <Form.Control required type="text" onChange={this.handleInputChange} name="code" defaultValue={qrCode || ''} />
              </Form.Group>
              <SubmitButtons
                handleClose={this.handleClose}
                disableSubmit={disableSubmit}
                submitLabel="Join"
                submittingLabel="Joining..."
              />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

JoinPlaytestingSession.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  qrCode: PropTypes.string,
};

JoinPlaytestingSession.defaultProps = {
  qrCode: null,
};

export default withRouter(connect()(JoinPlaytestingSession));
