/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import { Auth } from 'aws-amplify';


class SignOut extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    Auth.signOut()
      .then(() => console.log('Signing out'))
      .catch(err => console.log('Error Signing out: ', err));
  }

  render() {
    const { classes } = this.props;
    return (
      <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={this.signOut}>
        <ExitToAppIcon />
      </IconButton>
    );
  }
}

SignOut.propTypes = {
  classes: PropTypes.shape({
    iconButtonAvatar: PropTypes.string.isRequired,
  }).isRequired,
};

export default SignOut;
