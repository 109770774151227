import React from 'react';
import {
  Row, Container, Col, Tabs, Tab,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SurveyGraphList from './SurveyGraphs/SurveyGraphList';
import SurveyGameVersionSelector from './SurveyGameVersionSelector';
import SurveyStats from './SurveyStats';
import { getSurveyResults, clearSurveyResults } from '../../../actions/surveyresults';
import ResultsList from '../ResultsList';


class SurveyAggregatedResults extends React.Component {
  componentDidMount() {
    const { dispatch, match, team } = this.props;
    dispatch(getSurveyResults(team.teamObject.id, match.params.id));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearSurveyResults());
  }

  render() {
    const { surveyresults } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={8}>
            { surveyresults.list.length > 0 && (
              <SurveyGameVersionSelector
                results={surveyresults.list}
              />
            )}
            <Tabs defaultActiveKey="summary" id="uncontrolled-tab-example">
              <Tab eventKey="summary" title="Summary">
                <SurveyGraphList results={surveyresults.filteredList} />
              </Tab>
              <Tab eventKey="results" title="Results">
                <ResultsList results={surveyresults.filteredList} />
              </Tab>
              <Tab eventKey="edit" title="Edit" disabled />
            </Tabs>
          </Col>
          <Col sm={4}>
            <SurveyStats
              results={surveyresults.filteredList}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

SurveyAggregatedResults.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      Name: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  surveyresults: PropTypes.shape({
    questionnaires: PropTypes.arrayOf(
      PropTypes.shape({
        Status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        SurveyTitle: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    filteredList: PropTypes.arrayOf(
      PropTypes.shape({
        Status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        SurveyTitle: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        Status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        SurveyTitle: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default connect()(SurveyAggregatedResults);
