import React from 'react';
import { Form, Button, ButtonGroup } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StarRatingComponent from 'react-star-rating-component';
import PropTypes from 'prop-types';
import { updateSurveyresult } from '../../actions/surveyresults';


class Questionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.renderQuestion = this.renderQuestion.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStarClick = this.handleStarClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.state = {
      responses: props.survey.Responses || {},
      createFormValidated: false,
      disableSubmit: false,
      disableSave: false,
    };
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { responses } = this.state;
    if (target.type === 'checkbox') {
      if (target.checked) {
        if (name in responses) {
          this.setState(prevState => ({
            responses: {
              ...prevState.responses,
              [name]: [target.id, ...prevState.responses[name]],
            },
          }));
        } else {
          this.setState(prevState => ({
            responses: {
              ...prevState.responses,
              [name]: [target.id],
            },
          }));
        }
      } else if (name in responses) {
        this.setState(prevState => ({
          responses: {
            ...prevState.responses,
            [name]: [prevState.responses[name].filter(value => target.id !== value)],
          },
        }));
      }
    } else {
      const value = target.type === 'radio' ? target.id : target.value;
      this.setState(prevState => ({
        responses: {
          ...prevState.responses,
          [name]: value,
        },
      }));
    }
  }

  handleStarClick(nextValue, prevValue, name) {
    const event = { target: { name, type: 'text', value: nextValue } };
    this.handleInputChange(event);
  }

  handleSave() {
    const { survey, history, dispatch } = this.props;
    const { responses } = this.state;
    const updatedQuestionnaire = { id: survey.id, responses, statusSurvey: 'In Progress' };
    this.setState({
      disableSave: true,
      disableSubmit: true,
    });
    dispatch(updateSurveyresult(updatedQuestionnaire, history));
  }

  handleSubmit(event) {
    const { survey, history, dispatch } = this.props;
    const form = event.currentTarget;
    const { responses } = this.state;
    this.setState({
      createFormValidated: true,
    });
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      this.setState({
        createFormValidated: true,
        disableSave: true,
        disableSubmit: true,
      });
      const updatedQuestionnaire = { id: survey.id, responses, statusSurvey: 'Completed' };
      dispatch(updateSurveyresult(updatedQuestionnaire, history));
    }
  }

  renderQuestion(question) {
    const { responses } = this.state;
    const value = question.response_key in responses ? responses[question.response_key] : null;
    switch (question.question_type) {
      case 'rating': {
        // Assuming 1-10 as default
        let max = 10;
        if (question.value_range === '1-5') {
          max = 5;
        }

        return (
          <Form.Group>
            <StarRatingComponent
              name={question.response_key}
              starCount={max}
              value={parseInt(value, 10) || 0}
              required
              onStarClick={this.handleStarClick}
            />
          </Form.Group>
        );
      }
      case 'freeform':
        return (<Form.Control onChange={this.handleInputChange} required name={question.response_key} as="textarea" rows={question.rows} value={value || ''} />);
      case 'singlechoice':
        return (
          <div key="inline-radio" className="mb-3">
            {question.choices.map(choice => (
              <Form.Check onChange={this.handleInputChange} required key={choice} name={question.response_key} inline label={choice} type="radio" id={choice} defaultChecked={choice === value} />
            ))}
          </div>
        );
      case 'multichoice':
        return (
          <div key="inline-checkbox" className="mb-3">
            {question.choices.map(choice => (
              <Form.Check onChange={this.handleInputChange} key={choice} name={question.response_key} inline label={choice} type="checkbox" id={choice} defaultChecked={value ? value.includes(choice) : false} />
            ))}
          </div>
        );
      default:
        return (<Form.Control onChange={this.handleInputChange} required name={question.response_key} type="text" />);
    }
  }

  render() {
    const { survey } = this.props;
    const { createFormValidated, disableSave, disableSubmit } = this.state;
    return (
      <Form
        noValidate
        validated={createFormValidated}
        onSubmit={this.handleSubmit}
      >
        {survey.QuestionsList.map(question => (
          <Form.Group key={question.response_key}>
            <Form.Label>
              {question.title}
            </Form.Label>
            {this.renderQuestion(question)}
          </Form.Group>
        ))}
        <Form.Group>
          <ButtonGroup className="mr-2">
            <Button onClick={this.handleSave} variant="secondary" type="button" disabled={disableSave}>
              {disableSave ? 'Submitting...' : 'Save'}
            </Button>
          </ButtonGroup>
          <ButtonGroup className="mr-2">
            <Button variant="primary" type="submit" disabled={disableSubmit}>
              {disableSubmit ? 'Submitting...' : 'Submit'}
            </Button>
          </ButtonGroup>
        </Form.Group>
      </Form>
    );
  }
}

Questionnaire.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  survey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    Responses: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect()(Questionnaire));
