import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import QuestionRatingForm from './Questions/QuestionRatingForm';
import QuestionModal from './Questions/QuestionModal';
import QuestionFreeForm from './Questions/QuestionFreeForm';
import QuestionChoices from './Questions/QuestionChoices';


class SurveyQuestionsSelector extends Component {
  constructor(props) {
    super(props);
    this.questionTypes = {
      none: <div />,
      rating: <QuestionRatingForm />,
      freeform: <QuestionFreeForm />,
      singlechoice: <QuestionChoices />,
      multichoice: <QuestionChoices />,
    };
    this.state = {
      openQuestionModal: false,
      questionType: 'none',
    };
    this.handleOpenQuestionCreate = this.handleOpenQuestionCreate.bind(this);
    this.handleCloseQuestionCreate = this.handleCloseQuestionCreate.bind(this);
    this.handleClickAddQuestion = this.handleClickAddQuestion.bind(this);
    this.createQuestion = this.createQuestion.bind(this);
  }

  handleOpenQuestionCreate() {
    const { openQuestionModal } = this.state;
    if (!openQuestionModal) {
      this.setState({ openQuestionModal: true });
    }
  }

  handleCloseQuestionCreate() {
    this.setState({ openQuestionModal: false });
  }

  handleClickAddQuestion(key, event) {
    event.preventDefault();
    this.setState({
      openQuestionModal: true,
      questionType: key,
    });
  }

  createQuestion(metadata) {
    const { handleCreateQuestion } = this.props;
    handleCreateQuestion(metadata);
    this.setState({ openQuestionModal: false });
  }

  render() {
    const { questionType, openQuestionModal } = this.state;
    const questionComponent = this.questionTypes[questionType];
    return (
      <div>
        <h5>Add type of question</h5>
        <ListGroup>
          <ListGroup.Item action onClick={e => this.handleClickAddQuestion('rating', e)}>Rating</ListGroup.Item>
          <ListGroup.Item action onClick={e => this.handleClickAddQuestion('freeform', e)}>Free Form Text</ListGroup.Item>
          <ListGroup.Item action onClick={e => this.handleClickAddQuestion('singlechoice', e)}>Single Choice</ListGroup.Item>
          <ListGroup.Item action onClick={e => this.handleClickAddQuestion('multichoice', e)}>Multiple Choice</ListGroup.Item>
        </ListGroup>
        <QuestionModal
          open={openQuestionModal}
          handleClose={this.handleCloseQuestionCreate}
          questionType={questionType}
          handleCreateQuestion={this.createQuestion}
        >
          {questionComponent}
        </QuestionModal>
      </div>
    );
  }
}

SurveyQuestionsSelector.propTypes = {
  handleCreateQuestion: PropTypes.func.isRequired,
};

export default SurveyQuestionsSelector;
