import { actionTypes } from '../../actions';

function updateResponsesFromQuestionnaire(list, surveyresult) {
  return list.map((item) => {
    if (item.id !== surveyresult.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item, Responses: surveyresult.responses, Status: surveyresult.status,
    };
  });
}

export default function surveyresults(state = {}, action) {
  switch (action.type) {
    case actionTypes.get_questionnaires_succeeded:
      return { ...state, questionnaires: action.payload.questionnaires };
    case actionTypes.get_surveyresults_succeeded: {
      const completedResults = action.payload.surveyresults.filter(r => r.Status === 'Completed');
      return { ...state, list: completedResults, filteredList: completedResults };
    }
    case actionTypes.filter_surveyresults:
      return {
        ...state,
        filteredList: state.list.filter(r => r.GameTitle === action.payload.game
        && action.payload.versions.includes(r.Version)),
      };
    case actionTypes.clear_surveyresults:
      return { ...state, list: [], filteredList: [] };
    case actionTypes.join_playtestingsession_succeeded:
      return { ...state, questionnaires: [...state.questionnaires, action.payload.surveyresult] };
    case actionTypes.update_surveyresult_succeeded:
      return {
        ...state,
        list: updateResponsesFromQuestionnaire(state.list, action.payload.surveyresult),
      };
    default:
      return state;
  }
}
