import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  createButton: {
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  list: {
    backgroundColor: '#fff',
  },
  gridList: {
    margin: theme.spacing(2),
  },
});

const SurveyList = (props) => {
  const { surveys, classes, playtestingsessions } = props;
  return (
    <Grid container direction="column">
      <Grid item>
        <Paper className={classes.createButton}>
          <Link to="/surveys/createSurvey">
            <Button size="small" variant="contained" color="primary">
              Create Survey
            </Button>
          </Link>
        </Paper>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={5} className={classes.gridList}>
          <Typography variant="h6" component="h2">
            Surveys Results
          </Typography>
          <List className={classes.list}>
            {surveys.list.map(survey => (
              <Link to={`/surveys/${survey.id}`} key={survey.id}>
                <ListItem divider button>
                  {survey.Title}
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
        <Grid item xs={5} className={classes.gridList}>
          <Typography variant="h6" component="h2">
            Rules Feedback
          </Typography>
          <List className={classes.list}>
            {playtestingsessions.list.filter(s => s.Rulebook).map(playtestingsession => (
              <Link to={`/rules/summary/${playtestingsession.id}`} key={playtestingsession.id}>
                <ListItem divider button>
                  {playtestingsession.RulebookName}
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

SurveyList.propTypes = {
  surveys: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Team: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  playtestingsessions: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    createButton: PropTypes.string.isRequired,
    list: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(withRouter(SurveyList));
