import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import PrintIcon from '@material-ui/icons/Print';
import PropTypes from 'prop-types';

const url = 'https://www.meepleworkshop.com';

const QRCode = require('qrcode-react');

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  card: {
    margin: theme.spacing(1),
  },
  cardSpacing: {
    marginBottom: theme.spacing(1),
  },
  minCard: {
    minHeight: theme.spacing(5),
  },
});

const PlaytestingSession = (props) => {
  const {
    playtestingsessions, surveys, games, match, classes,
  } = props;
  const playtestingsession = playtestingsessions.list.find(g => g.id === match.params.id);
  if (playtestingsession === undefined) {
    return (
      <div>
        Error: Unable to find the playtesting session. Please try again
      </div>
    );
  }
  const game = games.list.find(g => g.id === playtestingsession.Game);
  const survey = surveys.list.find(g => g.id === playtestingsession.Survey);
  const componentRef = useRef();
  if (game === undefined || (survey === undefined && playtestingsession.Rulebook === undefined)) {
    return (
      <div>
        Error: Unable to find the playtesting session information. Please try again
      </div>
    );
  }
  return (
    <Grid container direction="column">
      <Grid xs={12} item spacing={2} className={classes.cardSpacing}>
        <Typography variant="h5" component="h2">
          {playtestingsession.Title}
        </Typography>
      </Grid>
      <Grid container ref={componentRef} direction="row" spacing={2}>
        <Grid item xs={7} className={classes.cardSpacing}>
          <Grid item className={classes.cardSpacing}>
            <Paper className={classes.paper}>
              <AppBar position="static" color="default" elevation={0}>
                <Toolbar className={classes.card}>
                  <Typography variant="h6" component="h2">
                    Sharing Information
                  </Typography>
                </Toolbar>
              </AppBar>
              <Grid item className={classes.card}>
                <Typography variant="h6" component="h2">
                  {`Code: ${playtestingsession.Code}`}
                </Typography>
                <QRCode value={`${url}/join/${playtestingsession.Code}`} />
                <Typography variant="h6" component="h2">
                  {`${url}/join/${playtestingsession.Code}`}
                </Typography>
                <ReactToPrint
                  trigger={() => (
                    <Button variant="contained" color="primary">
                      Print this out
                      {' '}
                      <PrintIcon />
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} justify="flex-end" xs={5} direction="column">
          <Grid item className={classes.cardSpacing}>
            <Paper className={classes.paper}>
              <AppBar position="static" color="default" elevation={0}>
                <Toolbar className={classes.card}>
                  <Typography variant="h6" component="h2">
                    Session Details
                  </Typography>
                </Toolbar>
              </AppBar>
              <Grid item className={classes.card}>
                <Typography variant="h6" component="h2">
                  {`Game: ${game.Title}`}
                </Typography>
                {playtestingsession.Version && (
                  <Typography variant="h6" component="h2">
                    {`Version: ${playtestingsession.Version}`}
                  </Typography>
                )}
                {survey && (
                  <Typography variant="h6" component="h2">
                    {`Survey: ${survey.Title}`}
                  </Typography>
                )}
                {playtestingsession.RulebookName && (
                  <Typography variant="h6" component="h2">
                    {`Rulebook: ${playtestingsession.RulebookName}`}
                  </Typography>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid xs item className={classes.minCard}>
            <Paper className={classes.paper}>
              <AppBar position="static" color="default" elevation={0}>
                <Toolbar className={classes.card}>
                  <Typography variant="h6" component="h2">
                    Testers
                  </Typography>
                </Toolbar>
              </AppBar>
              {playtestingsession.Testers && (
                <List>
                  {playtestingsession.Testers.map(tester => (
                    <ListItem key={tester}>
                      {tester}
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PlaytestingSession.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  surveys: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Team: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  playtestingsessions: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        Title: PropTypes.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  games: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(PlaytestingSession);
