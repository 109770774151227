import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filterSurveyResults } from '../../../actions/surveyresults';


class SurveyGameVersionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleGameChange = this.handleGameChange.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    const gamesVersions = SurveyGameVersionSelector.getGamesAndVersions(props.results);
    // Dirty trick to default to the first game of the results
    const selectedGame = gamesVersions.keys().next().value;
    this.state = {
      gamesVersions,
      selectedGame,
      selectedVersions: Array.from(gamesVersions.get(selectedGame).keys()),
    };
  }

  componentDidMount() {
    const { selectedGame, selectedVersions } = this.state;
    const { dispatch } = this.props;
    dispatch(filterSurveyResults(selectedGame, selectedVersions));
  }

  static getGamesAndVersions(results) {
    const games = new Map();
    results.forEach((result) => {
      if (!(games.has(result.GameTitle))) {
        games.set(result.GameTitle, new Map());
      }
      if (!(games.get(result.GameTitle).has(result.Version))) {
        games.get(result.GameTitle).set(result.Version, true);
      }
    });
    return games;
  }

  handleGameChange(event) {
    const { gamesVersions } = this.state;
    const { dispatch } = this.props;
    const selectedVersions = Array.from(gamesVersions.get(event.target.value).keys());
    const game = event.target.value;
    this.setState({
      selectedVersions,
      selectedGame: game,
    });
    dispatch(filterSurveyResults(game, selectedVersions));
  }

  handleVersionChange(event) {
    const { target } = event;
    let { selectedVersions } = this.state;
    const { selectedGame } = this.state;
    const { dispatch } = this.props;
    if (target.type === 'checkbox') {
      if (target.checked) {
        if (!(selectedVersions.includes(target.id))) {
          selectedVersions.push(target.id);
        }
      } else if (selectedVersions.includes(target.id)) {
        selectedVersions = selectedVersions.filter(version => version !== target.id);
      }
    }
    this.setState({
      selectedVersions,
    });
    dispatch(filterSurveyResults(selectedGame, selectedVersions));
  }

  render() {
    const { selectedGame, selectedVersions, gamesVersions } = this.state;
    return (
      <Form.Group controlId="exampleForm.ControlSelect1">
        <h3>Results Filter</h3>
        <Form.Label>Game</Form.Label>
        <Form.Control as="select" onChange={this.handleGameChange}>
          {Array.from(gamesVersions.keys()).map(game => (
            <option
              key={game}
              value={game}
              name={game}
            >
              {game}
            </option>
          ))}
        </Form.Control>
        <Form.Label>Versions</Form.Label>
        { (selectedGame && gamesVersions.size > 0) && (
          <div key="inline-checkbox" className="mb-3">
            {Array.from(gamesVersions.get(selectedGame).keys()).map(key => (
              <Form.Check
                onChange={this.handleVersionChange}
                key={key}
                name={key}
                inline
                label={key}
                type="checkbox"
                id={key}
                defaultChecked={selectedVersions && selectedVersions.length > 0
                  ? selectedVersions.includes(key) : false}
              />
            ))}
          </div>
        )}
      </Form.Group>
    );
  }
}

SurveyGameVersionSelector.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({
    Version: PropTypes.string.isRequired,
    QuestionsList: PropTypes.arrayOf(PropTypes.shape({
      question_type: PropTypes.string.isRequired,
      response_key: PropTypes.string.isRequired,
    })),
  })).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SurveyGameVersionSelector);
