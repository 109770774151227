import { toast } from 'react-toastify';
import APIClient from '../../utils/APIClient';
import { actionTypes } from '../index';


export function getSurveysSucceeded(surveys) {
  return {
    type: actionTypes.get_surveys_succeeded,
    payload: {
      surveys,
    },
  };
}

export function getSurveysError(error) {
  toast.error(`Error loading surveys: ${error}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.get_surveys_error,
    payload: {
      error,
    },
  };
}

export function getSurveys(team) {
  return (dispatch) => {
    APIClient.listObjects('surveys', team).then((resp) => {
      dispatch(getSurveysSucceeded(resp));
    }).catch((error) => {
      dispatch(getSurveysError(error));
    });
  };
}

export function createSurveySucceeded(survey, history) {
  history.push('/surveys');
  toast.success(`Successfully created new survey: ${survey.Title}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.create_survey_succeeded,
    payload: {
      survey,
    },
  };
}

export function createSurveyError(response, history) {
  history.push('/surveys');
  toast.error(`Error creating new survey: ${response}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.create_survey_error,
  };
}

export function createSurvey(survey, history) {
  return (dispatch) => {
    APIClient.createObject('surveys', survey)
      .then(({ id }) => {
        dispatch(createSurveySucceeded({ ...survey, id }, history));
      }).catch((response) => {
        dispatch(createSurveyError(response, history));
      });
  };
}
