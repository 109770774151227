/* eslint-disable no-underscore-dangle */
import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import throttle from 'lodash.throttle';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { createMuiTheme } from '@material-ui/core/styles';
import { Authenticator } from 'aws-amplify-react/dist/Auth';
import { SignIn, Greetings } from 'aws-amplify-react';
import { ThemeProvider } from '@material-ui/styles';
import config from './aws-exports';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './utils/localStorage';
import { actionTypes } from './actions';
import team from './reducers/team';
import games from './reducers/games';
import surveys from './reducers/surveys';
import surveyresults from './reducers/surveyresults';
import playtestingsessions from './reducers/playtestingsessions';
import CustomSignIn from './components/Home/CustomSignIn';


let theme = createMuiTheme({
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        marginLeft: theme.spacing(2),
        textAlign: 'left',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

Amplify.configure(config);

const defaultState = {
  team: {
    isLoaded: false,
  },
  games: { list: [], areLoaded: false },
  playtestingsessions: { list: [], areLoaded: false },
  surveys: { list: [], areLoaded: false },
  surveyresults: { list: [], filteredList: [], questionnaires: [] },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = loadState() || defaultState;

const appReducer = combineReducers({
  team, games, surveys, surveyresults, playtestingsessions,
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.user_sign_out) {
    return defaultState;
  }

  return appReducer(state, action);
};

const store = createStore(rootReducer,
  initialState, composeEnhancers(applyMiddleware(thunk)));

store.subscribe(throttle(() => {
  saveState({
    state: store.getState(),
  });
}, 1000));

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Username',
      key: 'username',
      required: true,
      displayOrder: 2,
      type: 'string',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 3,
      type: 'password',
    },
  ],
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Authenticator hide={[SignIn, Greetings]} amplifyConfig={config} signUpConfig={signUpConfig}>
        <CustomSignIn />
        <App />
      </Authenticator>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
