import React, { Component } from 'react';
import {
  Form, Row, Container, Col, Alert,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SurveyQuestionsSelector from './SurveyQuestionsSelector';
import QuestionsList from './QuestionsList';
import { createSurvey } from '../../actions/surveys';

const uuidv4 = require('uuid/v4');


class SurveyEditor extends Component {
  constructor(props) {
    super(props);
    this.handleCreateQuestion = this.handleCreateQuestion.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.handleCreateSurvey = this.handleCreateSurvey.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      metadataList: [],
      createFormValidated: false,
      showAtLeastOneQuestionAlert: false,
      surveyParams: {},
      disableSubmit: false,
    };
  }

  handleCreateQuestion(metadata) {
    this.setState(prevState => ({
      metadataList: [...prevState.metadataList, { ...metadata, response_key: uuidv4() }],
    }));
  }

  deleteQuestion(responseKey) {
    const { metadataList } = this.state;
    this.setState({
      metadataList: metadataList.filter(question => question.response_key !== responseKey),
    });
  }

  handleCreateSurvey(event) {
    const { metadataList, surveyParams } = this.state;
    const { team, history, dispatch } = this.props;
    const form = event.currentTarget;
    let showAtLeastOneQuestionAlert = false;
    event.preventDefault();
    event.stopPropagation();
    // Check if the form is valid and that it has at least 1 question
    if (form.checkValidity() === false || metadataList.length === 0) {
      if (metadataList.length === 0) {
        showAtLeastOneQuestionAlert = true;
      }
    } else {
      this.setState({
        disableSubmit: true,
      });
      const survey = {
        Title: surveyParams.title,
        QuestionsList: metadataList,
        Team: team.teamObject.id,
      };
      dispatch(createSurvey(survey, history));
    }
    this.setState({
      createFormValidated: true,
      showAtLeastOneQuestionAlert,
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const { surveyParams } = this.state;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const previousObj = surveyParams;
    previousObj[name] = value;
    this.setState({ surveyParams: previousObj });
  }

  render() {
    const {
      showAtLeastOneQuestionAlert, createFormValidated, metadataList, disableSubmit,
    } = this.state;
    return (
      <Container>
        <Row>
          <Col sm={8}>
            {/* this should be dismissible, but it doesn't seem to work:
              https://github.com/react-bootstrap/react-bootstrap/issues/307 */}
            {showAtLeastOneQuestionAlert && (
              <Alert dismissible variant="danger">
                <p>
                  You need to create at least 1 question to save the survey.
                </p>
              </Alert>
            )}
            <Form
              noValidate
              validated={createFormValidated}
              onSubmit={this.handleCreateSurvey}
            >
              <Form.Group controlId="formSurveyTitle">
                <h4>Survey Title</h4>
                <Form.Control type="text" placeholder="Enter survey title" name="title" onChange={this.handleInputChange} required />
                <Form.Text className="text-muted">
                Used for internal reference
                </Form.Text>
              </Form.Group>
              <QuestionsList deleteQuestion={this.deleteQuestion} metadataList={metadataList} />
              <Button type="submit" variant="contained" color="primary" disabled={disableSubmit}>
                {disableSubmit ? 'Creating Survey...' : 'Create Survey'}
              </Button>
            </Form>
          </Col>
          <Col sm={3}>
            <SurveyQuestionsSelector handleCreateQuestion={this.handleCreateQuestion} />
          </Col>
        </Row>
      </Container>
    );
  }
}

SurveyEditor.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    teamObject: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isLoaded: PropTypes.bool.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    team: state.team,
  };
}

export default withRouter(connect(mapStateToProps)(SurveyEditor));
