import React from 'react';
import {
  XYPlot, XAxis,
  YAxis, HorizontalGridLines, VerticalGridLines,
  VerticalBarSeries, DiscreteColorLegend,
} from 'react-vis';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';

class SingleChoiceGraph extends React.Component {
  static calculateOcurrences(question) {
    const responsesBySelectedResponse = {};
    Object.keys(question.results).forEach((version) => {
      question.results[version].forEach((result) => {
        if (Array.isArray(result)) {
          result.forEach((choice) => {
            if (!(Object.prototype.hasOwnProperty.call(responsesBySelectedResponse, choice))) {
              responsesBySelectedResponse[choice] = {};
            }
            if (!(Object.prototype.hasOwnProperty.call(
              responsesBySelectedResponse[choice], version,
            ))) {
              responsesBySelectedResponse[choice][version] = 0;
            }
          });
        } else {
          if (!(Object.prototype.hasOwnProperty.call(responsesBySelectedResponse, result))) {
            responsesBySelectedResponse[result] = {};
          }
          if (!(Object.prototype.hasOwnProperty.call(
            responsesBySelectedResponse[result], version,
          ))) {
            responsesBySelectedResponse[result][version] = 0;
          }
        }
        // For multiple responses, we have an array instead of just a response
        if (Array.isArray(result)) {
          result.forEach((choice) => {
            responsesBySelectedResponse[choice][version] += 1;
          });
        } else {
          responsesBySelectedResponse[result][version] += 1;
        }
      });
    });
    return responsesBySelectedResponse;
  }

  static returnVerticalBarArray(responseMap) {
    const data = [];
    Object.keys(responseMap).forEach((version) => {
      data.push({ x: version, y: responseMap[version] });
    });
    return data;
  }

  render() {
    const { question } = this.props;
    const responsesBySelectedResponse = SingleChoiceGraph.calculateOcurrences(question);
    const responseKeys = Object.keys(responsesBySelectedResponse);
    return (
      <div>
        <XYPlot width={300} height={300} xType="ordinal" stackBy="y">
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis />
          <YAxis />
          {responseKeys.map(key => (
            <VerticalBarSeries
              key={key}
              data={SingleChoiceGraph.returnVerticalBarArray(responsesBySelectedResponse[key])}
            />
          ))}
        </XYPlot>
        <DiscreteColorLegend items={responseKeys} />
      </div>
    );
  }
}

SingleChoiceGraph.propTypes = {
  question: PropTypes.PropTypes.shape({
    results: PropTypes.shape({
      version: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default SingleChoiceGraph;
