import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';


class QuestionCard extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
  }

  handleDeleteQuestion() {
    const { question, deleteQuestion } = this.props;
    deleteQuestion(question.response_key);
  }

  render() {
    const { question } = this.props;
    return (
      <Card border="light" style={{ width: '18rem' }}>
        <Card.Header>
          <Button size="sm" onClick={this.handleDeleteQuestion} href="#">
            <FaTimes />
          </Button>
          {`    ${question.title}`}
        </Card.Header>
        <Card.Body>
          <Card.Text>
            Question Type:
            {' '}
            {question.question_type}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

QuestionCard.propTypes = {
  question: PropTypes.shape({
    question_type: PropTypes.string.isRequired,
    response_key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  deleteQuestion: PropTypes.func.isRequired,
};

export default QuestionCard;
