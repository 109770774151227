import { toast } from 'react-toastify';
import APIClient from '../../utils/APIClient';
import { actionTypes } from '../index';


export function getSurveyResultsSucceeded(surveyresults) {
  return {
    type: actionTypes.get_surveyresults_succeeded,
    payload: {
      surveyresults,
    },
  };
}

export function getSurveyResultsError(error) {
  toast.error(`Error loading survey results: ${error}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.get_surveyresults_error,
    payload: {
      error,
    },
  };
}

export function getSurveyResults(team, survey) {
  return (dispatch) => {
    APIClient.listResultsBySurvey(team, survey).then((resp) => {
      dispatch(getSurveyResultsSucceeded(resp));
    }).catch((error) => {
      dispatch(getSurveyResultsError(error));
    });
  };
}

export function getSurveyResultsByPlaytestingSession(playtestingSessionId) {
  return (dispatch) => {
    APIClient.listObjects('surveyresults', playtestingSessionId, 'playtestingSession').then((resp) => {
      dispatch(getSurveyResultsSucceeded(resp));
    }).catch((error) => {
      dispatch(getSurveyResultsError(error));
    });
  };
}

export function getQuestionnairesSucceeded(questionnaires) {
  return {
    type: actionTypes.get_questionnaires_succeeded,
    payload: {
      questionnaires,
    },
  };
}

export function getQuestionnairesError(error) {
  toast.error(`Error loading questionnaires: ${error}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.get_questionnaires_error,
    payload: {
      error,
    },
  };
}

export function getQuestionnaires() {
  return (dispatch) => {
    APIClient.listObjects('surveyresults').then((resp) => {
      dispatch(getQuestionnairesSucceeded(resp));
    }).catch((error) => {
      dispatch(getQuestionnairesError(error));
    });
  };
}

export function filterSurveyResults(game, versions) {
  return {
    type: actionTypes.filter_surveyresults,
    payload: {
      game,
      versions,
    },
  };
}

export function clearSurveyResults() {
  return {
    type: actionTypes.clear_surveyresults,
  };
}

export function updateSurveyresultSucceeded(surveyresult, history) {
  history.push('/');
  toast.success('Successfully updated survey result', {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.update_surveyresult_succeeded,
    payload: {
      surveyresult,
    },
  };
}

export function updateSurveyresultError(response) {
  toast.error(`Error saving survey results: ${response}`, {
    position: toast.POSITION.TOP_CENTER,
  });
  return {
    type: actionTypes.update_surveyresult_error,
  };
}

export function updateSurveyresult(updatedQuestionnaire, history) {
  return (dispatch) => {
    APIClient.updateObject('surveyresults', updatedQuestionnaire)
      .then(() => {
        dispatch(updateSurveyresultSucceeded(updatedQuestionnaire, history));
      }).catch((response) => {
        dispatch(updateSurveyresultError(response));
      });
  };
}
