import React, { Component } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import QuestionGenericForm from './QuestionGenericForm';
import SubmitButtons from '../../Forms/SubmitButtons';


class QuestionModal extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.metadata = {};
    this.state = {
      createQuestionValidated: false,
      disableSubmit: false,
    };
  }

  handleInputChange(event) {
    const { questionType } = this.props;
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.metadata.question_type = questionType;
    this.metadata[name] = value;
  }

  handleSubmit(event) {
    const { handleCreateQuestion } = this.props;
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === true) {
      this.setState({ disableSubmit: true });
      handleCreateQuestion(this.metadata);
      this.metadata = {};
      this.setState({ createQuestionValidated: false, disableSubmit: false });
    } else {
      this.setState({ createQuestionValidated: true });
    }
  }

  render() {
    const { open, handleClose, children } = this.props;
    const { createQuestionValidated, disableSubmit } = this.state;
    return (
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            validated={createQuestionValidated}
            onSubmit={e => this.handleSubmit(e)}
          >
            <QuestionGenericForm onChangeInput={this.handleInputChange} />
            {React.cloneElement(children, { onChangeInput: this.handleInputChange })}
            <SubmitButtons
              handleClose={handleClose}
              disableSubmit={disableSubmit}
              submitLabel="Create"
              submittingLabel="Creating..."
            />
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

QuestionModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  questionType: PropTypes.string.isRequired,
  handleCreateQuestion: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default QuestionModal;
