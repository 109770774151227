import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CardActions from '@material-ui/core/CardActions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';


const EditArchiveCardIcons = (props) => {
  const { viewLink } = props;
  return (
    <CardActions>
      <Link to={viewLink}>
        <Fab size="small" color="primary" aria-label="View">
          <VisibilityIcon />
        </Fab>
      </Link>
      <Fab disabled size="small" aria-label="Edit">
        <EditIcon />
      </Fab>
      <Fab disabled size="small" color="secondary" aria-label="Delete">
        <DeleteIcon />
      </Fab>
    </CardActions>
  );
};

EditArchiveCardIcons.propTypes = {
  viewLink: PropTypes.string.isRequired,
};

export default EditArchiveCardIcons;
