import React, { Component } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addMember, addMemberError } from '../../actions/teams';


class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMember: '',
    };
    this.addNewMember = this.addNewMember.bind(this);
    this.handleMembersChange = this.handleMembersChange.bind(this);
  }

  handleMembersChange(event) {
    const { value } = event.target;
    this.setState({ newMember: value });
  }

  addNewMember() {
    const { newMember } = this.state;
    const { team, dispatch } = this.props;
    const request = { id: team.id, newMember, name: team.Name };
    if (newMember !== '') {
      dispatch(addMember(request));
      this.setState({
        newMember: '',
      });
    } else {
      dispatch(addMemberError('A new member cannot be empty'));
    }
  }

  render() {
    const { newMember } = this.state;
    return (
      <InputGroup className="mb-3">
        <FormControl required type="text" onChange={this.handleMembersChange} name="name" value={newMember} />
        <InputGroup.Append>
          <Button onClick={this.addNewMember} variant="outline-secondary">Add New Member</Button>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

AddMember.propTypes = {
  dispatch: PropTypes.func.isRequired,
  team: PropTypes.shape({
    Name: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect()(AddMember);
