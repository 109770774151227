import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EditArchiveCardIcons from '../Forms/EditArchiveCardIcons';

const QRCode = require('qrcode-react');

const url = 'https://www.meepleworkshop.com';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    minWidth: 275,
  },
  media: {
    height: 140,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PlaytestingSessionsCard = (props) => {
  const classes = useStyles();
  const { playtestingsession } = props;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" component="h2">
          {playtestingsession.Title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {playtestingsession.GameTitle}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {playtestingsession.Version}
        </Typography>
        <QRCode value={`${url}/join/${playtestingsession.Code}`} />
        <Typography className={classes.pos} color="textSecondary">
          {playtestingsession.Code}
        </Typography>
      </CardContent>
      <EditArchiveCardIcons viewLink={`/playtestingSessions/${playtestingsession.id}`} />
    </Card>
  );
};

PlaytestingSessionsCard.propTypes = {
  playtestingsession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    Title: PropTypes.isRequired,
  }).isRequired,
};

export default PlaytestingSessionsCard;
